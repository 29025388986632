import "./Debugging.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import reviews_file from "./reviews_d_hotels.json"; //change file later on
//Add model advice, provide option to decide whether that is correct or not
//Give participants the option to adjust font-size
function Debugging() {
  const [showModal, setShowModal] = useState(false);

  const location = useLocation();
  console.log(location.state);
  const id = location.state.id;
  const task_ordering_group = location.state.task_ordering_group;
  const batch_order = location.state.batch_order;
  const [attention_checks_failed, setAttentionChecksFailed] = useState(
    location.state.attention_checks_failed
  );
  //console.log("Attention checks failed: " + attention_checks_failed);
  // console.log("id: " + id);

  const navigate = useNavigate();

  // let init_id_order = shuffle([0, 1, 2, 3]);

  // init_id_order.splice(0, 0, 4);

  // Order in the json file is [instructive, instructive, uninstructive, uninstructive, instructive, instructive, uninstructive, uninstructive]

  // 0 is random order
  // 1 is decreasing order (good to bad)
  // 2 is increasing order (bad to good)

  let init_id_order = [];
  let init_prediction_order = [];

  if (task_ordering_group === 0) {
    let good_reviews = shuffle([0, 1, 4, 5]);
    let bad_reviews = shuffle([2, 3, 6, 7]);

    init_id_order.push(good_reviews.pop());
    init_id_order.push(good_reviews.pop());
    init_id_order.push(bad_reviews.pop());
    init_id_order.push(bad_reviews.pop());
    init_id_order.push(good_reviews.pop());
    init_id_order.push(good_reviews.pop());
    init_id_order.push(bad_reviews.pop());
    init_id_order.push(bad_reviews.pop());

    init_prediction_order = [
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
    ];
    // console.log("Init id order before shuffle: " + init_id_order.toString());
    // console.log(
    //   "Init pred order before shuffle: " + init_prediction_order.toString()
    // );
    shuffle2(init_id_order, init_prediction_order);
    // console.log("Init id order after shuffle: " + init_id_order.toString());
    // console.log(
    //   "Init pred order after shuffle: " + init_prediction_order.toString()
    // );
  } else if (task_ordering_group === 1) {
    let good_reviews = shuffle([0, 1, 4, 5]);
    let bad_reviews = shuffle([2, 3, 6, 7]);

    init_id_order.push(good_reviews.pop());
    init_id_order.push(good_reviews.pop());
    init_id_order.push(bad_reviews.pop());
    init_id_order.push(bad_reviews.pop());
    init_id_order.push(good_reviews.pop());
    init_id_order.push(good_reviews.pop());
    init_id_order.push(bad_reviews.pop());
    init_id_order.push(bad_reviews.pop());

    init_prediction_order = [
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
    ];
  } else {
    let good_reviews = shuffle([0, 1, 4, 5]);
    let bad_reviews = shuffle([2, 3, 6, 7]);

    init_id_order.push(bad_reviews.pop());
    init_id_order.push(bad_reviews.pop());
    init_id_order.push(good_reviews.pop());
    init_id_order.push(good_reviews.pop());
    init_id_order.push(bad_reviews.pop());
    init_id_order.push(bad_reviews.pop());
    init_id_order.push(good_reviews.pop());
    init_id_order.push(good_reviews.pop());

    init_prediction_order = [
      false,
      false,
      false,
      false,
      true,
      true,
      true,
      true,
      true,
    ];
  }

  init_id_order.splice(4, 0, 8);
  init_prediction_order.splice(4, 0, true);

  //If the length of the reviews is changed, update this array
  const [id_order, setIdOrder] = useState(init_id_order);
  const [prediction_order, setPredictionOrder] = useState(
    init_prediction_order
  );

  const [showDebuggingPanelDecider, setShowDebuggingPanelDecider] =
    useState(false);

  const [current_review_counter, setCurrentReviewCounter] = useState(0);

  const [noneClickedButNextPressed, setNoneClickedButNextPressed] =
    useState(false);

  const [logString, setLogString] = useState("");

  // useEffect(() => {
  //   console.log(logString);
  // }, [logString]);

  useEffect(() => {
    setLogString(
      logString + "Current review: " + id_order[current_review_counter] + "\n"
    );
  }, [current_review_counter]);

  const valueScoreMap = new Map();

  valueScoreMap.set(-0.25, -2);
  valueScoreMap.set(-0.08, -1);
  valueScoreMap.set(0, 0);
  valueScoreMap.set(0.08, 1);
  valueScoreMap.set(0.25, 2);

  const scoreTextMap = new Map();

  scoreTextMap.set(
    -2,
    "<mark style='background-color: white; color: red; font-weight: 500;'>deceptive</mark>"
  );
  scoreTextMap.set(
    -1,
    "<mark style='background-color: white; color: lightcoral; font-weight: 500;'>somewhat deceptive</mark>"
  );
  scoreTextMap.set(
    0,
    "<mark style='background-color: white; font-weight: 500;'>neutral</mark>"
  );
  scoreTextMap.set(
    1,
    "<mark style='background-color: white;color: green; font-weight: 500;'>somewhat genuine</mark>"
  );
  scoreTextMap.set(
    2,
    "<mark style='background-color: white;color: darkgreen; font-weight: 500;'>genuine</mark>"
  );

  let reviews = reviews_file.reviews;

  let hwords = [];

  let review_html_strings = [];

  let initWordMap = [];

  let initAnswers = [];

  const [clicked, setClicked] = useState("");

  const [goPage2, setGoPage2] = useState(false);

  const [goNextReview, setGoNextReview] = useState(false);

  for (let i = 0; i < reviews.length; i++) {
    initWordMap.push(new Map());
    initAnswers.push(new Map());
    for (let j = 0; j < reviews[i].hwords.length; j++) {
      initWordMap[i].set(reviews[i].hwords[j], reviews[i].attributes[j]);
      initAnswers[i].set(reviews[i].hwords[j], reviews[i].initial_answers[j]);
    }
  }

  const [answers, setAnswers] = useState(initAnswers);
  //console.log(initAnswers);

  // Variable that contains a list of maps of (highlighted word, highlight attribution) entries
  const [wordMap, setWordMap] = useState(initWordMap);

  for (let i = 0; i < reviews.length; i++) {
    let temp = new Map();
    for (const item in reviews[i].hwords) {
      if (Object.hasOwnProperty.call(reviews[i].hwords, item)) {
        temp.set(reviews[i].hwords[item], false);
      }
    }
    hwords.push(temp);
  }

  const [hWords, setHWords] = useState(hwords);

  function setFalse(value, key, map) {
    map.set(key, false);
  }

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  // let t1 = [1, 2, 3, 4]
  // let t2 = [1, 2, 3, 4]

  // shuffle2(t1, t2)
  // console.log(t1)
  // console.log(t2)

  function shuffle2(array, array2) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];

      [array2[currentIndex], array2[randomIndex]] = [
        array2[randomIndex],
        array2[currentIndex],
      ];
    }

    return array;
  }

  //order of updating is not right. state lags behind
  // https://stackoverflow.com/questions/62994853/react-hooks-setstate-lagging-one-step-behind seems to have the solution
  const buttonSelect = (e) => {
    //console.log(e.target.value);
    if (e.target.value !== undefined) {
      setLogString(logString + "wc: " + e.target.value + "\n");
      hwords[id_order[current_review_counter]].forEach(setFalse);
      hwords[id_order[current_review_counter]].set(e.target.value, true);
      setHWords(structuredClone(hwords));
      setShowDebuggingPanelDecider(true);

      //console.log(hwords);
    } else {
      hwords[id_order[current_review_counter]].forEach(setFalse);
      setHWords(structuredClone(hwords));
      setShowDebuggingPanelDecider(false);
    }
  };

  useEffect(() => {
    //console.log(hWords);
  }, [hWords]);

  function borderToggle(word) {
    if (hWords[id_order[current_review_counter]].get(word)) {
      return "black";
    } else {
      return "white";
    }
  }

  // Function to display the reviews
  let process_text = (tokens) => {
    let rgb = (x) => {
      if (x < 0) return "255,0,0";
      else if (x > 0) {
        return "0,225,0";
      } else {
        return "209,213,219";
      }
    };
    let alpha = (x) => {
      if (x === 0) {
        return 1;
      }
      return Math.pow(Math.abs(x), 0.4);
    };

    let token_marks = "";

    for (let i = 0; i < tokens.length; i++) {
      if (
        wordMap[id_order[current_review_counter]].get(tokens[i]) !== undefined
      ) {
        token_marks +=
          '<button style="background-color:rgba(' +
          rgb(wordMap[id_order[current_review_counter]].get(tokens[i])) +
          "," +
          alpha(wordMap[id_order[current_review_counter]].get(tokens[i])) +
          "); border: 1px solid " +
          borderToggle(tokens[i]) +
          '"; value="' +
          tokens[i] +
          '";>' +
          tokens[i] +
          "</button> ";
      } else {
        token_marks +=
          '<mark style="background-color:rgba(' +
          rgb(0) +
          "," +
          0 +
          ')">' +
          tokens[i] +
          "</mark> ";
      }
    }
    return token_marks;
  };

  // Pre-process reviews to be displayed
  for (let i = 0; i < reviews.length; i++) {
    review_html_strings.push(process_text(reviews[i].tokens));
  }

  let buttonClick = (e) => {
    setLogString(
      logString +
        "bc: (panel button " +
        valueScoreMap.get(parseFloat(e.target.value)) +
        ")\n"
    );
    let selected = false;
    let currentSelected = "";
    hWords[id_order[current_review_counter]].forEach((value, key, map) => {
      if (value) {
        selected = true;
        currentSelected = key;
      }
    });
    if (selected) {
      for (
        let i = 0;
        i < reviews[id_order[current_review_counter]].hwords.length;
        i++
      ) {
        if (
          reviews[id_order[current_review_counter]].hwords[i] ===
          currentSelected
        ) {
          let temp = structuredClone(wordMap);
          temp[id_order[current_review_counter]].set(
            currentSelected,
            parseFloat(e.target.value)
          );
          setWordMap(temp);
          let tempAnswers = structuredClone(answers);
          tempAnswers[id_order[current_review_counter]].set(
            currentSelected,
            valueScoreMap.get(parseFloat(e.target.value))
          );
          setAnswers(tempAnswers);
          //console.log(tempAnswers);
        }
      }
    }
  };

  function checkClassification() {
    if (
      reviews[id_order[current_review_counter]].classification === "t" &&
      prediction_order[current_review_counter]
    ) {
      return '<p style="color:green">Genuine</p>';
    } else if (
      reviews[id_order[current_review_counter]].classification === "t" &&
      !prediction_order[current_review_counter]
    ) {
      return '<p style="color:red">Deceptive</p>';
    } else if (
      reviews[id_order[current_review_counter]].classification === "d" &&
      prediction_order[current_review_counter]
    ) {
      return '<p style="color:red">Deceptive</p>';
    } else {
      return '<p style="color:green">Genuine</p>';
    }
  }

  function showClassification() {
    return (
      <p className="flex-row flex b-4">
        <mark className="bg-white font-bold">AI advice:&nbsp;</mark>
        <div
          className=""
          dangerouslySetInnerHTML={{
            __html: checkClassification(),
          }}
        />
      </p>
    );
  }

  const onClickCorrect = () => {
    setLogString(logString + "bc: correct\n");
    //console.log("c");
    setClicked("c");
    setNoneClickedButNextPressed(false);
  };

  const onClickIncorrect = () => {
    setLogString(logString + "bc: incorrect\n");
    //console.log("i");
    setClicked("i");
    setNoneClickedButNextPressed(false);
  };

  const checkClickedCorrect = () => {
    if (clicked === "c") {
      return "bg-cyan-600 border-gray-600 border-2 font-semibold";
    } else {
      return "bg-cyan-300";
    }
  };

  function checkClickedIncorrect() {
    if (clicked === "i") {
      return "bg-cyan-600 border-gray-600 border-2 font-semibold";
    } else {
      return "bg-cyan-300";
    }
  }

  const clickNext = () => {
    if (countDown === 0) {
      if (current_review_counter < reviews.length) {
        setLogString(logString + "bc: Next (next review) \n");
        setCurrentReviewCounter(current_review_counter + 1);
        setShowDebuggingPanelDecider(false);
        setGoPage2(false);
        setClicked("");
        setCountDown(countDownValue);
      }
      if (current_review_counter === reviews.length - 2) {
        setGoNextReview(true);
      }
    } else {
      if (clicked === "") {
        setNoneClickedButNextPressed(true);
      }
      setShowTimerText(true);
    }
  };

  function sendAttentionCheckInfo(atp) {
    var json_response = {};
    json_response.id = id;
    json_response.stage = "debugging";
    json_response.passed = atp;
    fetch(
      "https://thesis-crowdworker-app-backend-k5cvyplkba-ez.a.run.app/user-group/attention-checks/main",
      {
        mode: "no-cors",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(json_response),
      }
    );
  }

  const clickNextAttentionCheckRight = () => {
    if (current_review_counter < reviews.length) {
      sendAttentionCheckInfo(true);
      setCurrentReviewCounter(current_review_counter + 1);
      setShowDebuggingPanelDecider(false);
      setGoPage2(false);
      setClicked("");
      setCountDown(countDownValue);
    }
    if (current_review_counter === reviews.length - 2) {
      setGoNextReview(true);
    }
  };

  // useEffect(() => {
  //   console.log(logString);
  // }, [logString]);

  const clickNextAttentionCheckWrong = () => {
    setAttentionChecksFailed(attention_checks_failed + 1);
    if (current_review_counter < reviews.length) {
      sendAttentionCheckInfo(false);
      setCurrentReviewCounter(current_review_counter + 1);
      setShowDebuggingPanelDecider(false);
      setGoPage2(false);
      setClicked("");
      setCountDown(countDownValue);
    }
    if (current_review_counter === reviews.length - 2) {
      setGoNextReview(true);
    }
  };

  const clickNextPage = () => {
    if (countDown !== 0) {
      if (clicked === "") {
        setNoneClickedButNextPressed(true);
      }
      setShowTimerText(true);
    } else {
      setLogString(logString + "bc: Next (next stage) \n");
      var json_response = {};
      json_response.id = id;
      json_response.logs = logString;
      fetch(
        "https://thesis-crowdworker-app-backend-k5cvyplkba-ez.a.run.app/user-group/debugging-logs",
        {
          mode: "no-cors",
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(json_response),
        }
      );

      let json_response2 = {};
      json_response2.id = id;
      json_response2.review_order = id_order.toString();

      fetch(
        "https://thesis-crowdworker-app-backend-k5cvyplkba-ez.a.run.app/user-goup/debugging012",
        {
          mode: "no-cors",
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(json_response2),
        }
      );

      navigate("/nasa_tlx/", {
        state: {
          attention_checks_failed: attention_checks_failed,
          id: id,
          task_ordering_group: task_ordering_group,
          batch_order: batch_order,
        },
      });
    }
  };

  function showNextButtonAttentionCheck() {
    let answers_values = Array.from(
      answers[id_order[current_review_counter]].values()
    );
    let correct_answers_values =
      reviews[id_order[current_review_counter]].answers;
    if (
      answers_values.toString() === correct_answers_values.toString() &&
      clicked === "c"
    ) {
      return (
        <button
          onClick={clickNextAttentionCheckRight}
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
          value="next"
        >
          Next
        </button>
      );
    } else if (attention_checks_failed >= 1) {
      return (
        <a
          onClick={clickWrong}
          href="https://app.prolific.co/submissions/complete?cc=C11CKFDX"
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
        >
          Next
        </a>
      );
    } else {
      return (
        <button
          onClick={clickNextAttentionCheckWrong}
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
          value="next"
        >
          Next
        </button>
      );
    }
  }

  function clickWrong() {
    sendAttentionCheckInfo(false);
  }

  const countDownValue = 0;

  const [countDown, setCountDown] = useState(countDownValue);
  const [showTimerText, setShowTimerText] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
        //console.log("Countdown: " + countDown);
      } else {
        setShowTimerText(false);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);

  function displayTimerText() {
    if (showTimerText) {
      return (
        <p className="text-red-500 font-bold">
          We expect you to spend at least 30 seconds on this task. If you
          already have correctly debugged the review, good job! Think about why
          your answers are correct.
        </p>
      );
    } else {
      return;
    }
  }

  // let answers_values = Array.from(
  //   answers[id_order[current_review_counter]].values()
  // );
  // //console.log(answers_values);

  const calculateListValues = () => {
    let answers_values = Array.from(
      answers[id_order[current_review_counter]].values()
    );
    let correct_answers_values =
      reviews[id_order[current_review_counter]].answers;
    let list_values = "";

    //console.log("The values of the answers are " + answers_values);

    for (let i = 0; i < answers_values.length; i++) {
      if (answers_values[i] !== correct_answers_values[i]) {
        let actual_review_classification = "";

        if (reviews[id_order[current_review_counter]].classification === "t") {
          actual_review_classification = "genuine";
        } else {
          actual_review_classification = "deceptive";
        }

        list_values +=
          '<li><mark style="background-color: white; font-weight: bold;">' +
          reviews[id_order[current_review_counter]].hwords[i] +
          "</mark> was labeled as " +
          scoreTextMap.get(answers_values[i]) +
          ". In this context however, it is usually an indicator of the review being " +
          scoreTextMap.get(correct_answers_values[i]) +
          "</li>";
      }
    }
    //console.log(list_values);
    return list_values;
  };

  const calculateResultsMessage = () => {
    let answers_values = Array.from(
      answers[id_order[current_review_counter]].values()
    );
    let correct_answers_values =
      reviews[id_order[current_review_counter]].answers;
    let error_counter = 0;

    for (let i = 0; i < answers_values.length; i++) {
      if (answers_values[i] !== correct_answers_values[i]) {
        error_counter++;
      }
    }
    if (error_counter === 0) {
      return (
        <h1 className="text-2xl text-green-600 opacity-80 font-bold">
          Well Done, you found all of the wrong text highlights identified by
          the AI systems!
        </h1>
      );
    } else if (error_counter > 0 && error_counter <= 3) {
      return (
        <h1 className="text-2xl text-orange-600 opacity-80 font-bold">
          You correctly identified the contribution of some words to the AI
          system's advice. Here's a brief summary of the ones you missed:
        </h1>
      );
    } else {
      return (
        <h1 className="text-2xl text-red-600 opacity-80 font-bold">
          It can be tricky to correct all of the AI's text highlights! Here's a
          brief summary of the ones you missed:
        </h1>
      );
    }
  };

  const calculateMachinePredictionText = () => {
    if (prediction_order[current_review_counter] && clicked === "c") {
      return (
        <p className="bg-green-100 text-lg">
          <mark className="underline underline-offset-1 bg-green-100 font-semibold">
            The AI advice was{" "}
            <mark className="font-bold bg-green-200">correct</mark>!
          </mark>{" "}
          You indicated that the advice was{" "}
          <mark className="font-bold bg-green-200">correct</mark>. You were
          right, good job finding that out!&nbsp;
        </p>
      );
    } else if (!prediction_order[current_review_counter] && clicked === "i") {
      return (
        <p className="bg-green-100 text-lg">
          <mark className="underline underline-offset-1 bg-green-100 font-semibold">
            The AI advice was{" "}
            <mark className="font-bold bg-green-200">incorrect</mark>!
          </mark>{" "}
          You indicated that the advice was{" "}
          <mark className="font-bold bg-green-200">incorrect</mark>. You were
          right, good job finding that out!&nbsp;
        </p>
      );
    } else if (prediction_order[current_review_counter] && clicked === "i") {
      return (
        <p className="bg-red-100 text-lg">
          <mark className="underline underline-offset-1 bg-red-100 font-semibold">
            The AI advice was{" "}
            <mark className="font-bold bg-red-200">correct</mark>!
          </mark>{" "}
          You indicated that the advice was{" "}
          <mark className="font-bold bg-red-200">incorrect</mark>. This is not
          the correct answer.&nbsp;
        </p>
      );
    } else {
      return (
        <p className="bg-red-100 text-lg">
          <mark className="underline underline-offset-1 bg-red-100 font-semibold">
            The AI advice was{" "}
            <mark className="font-bold bg-red-200">incorrect</mark>!
          </mark>{" "}
          You indicated that the advice was{" "}
          <mark className="font-bold bg-red-200">correct</mark>. This is not the
          correct answer. You have been misled by the AI advice.&nbsp;
        </p>
      );
    }
  };

  const clickNextResult = () => {
    if (clicked !== "") {
      setLogString(logString + "bc: next (next page)\n");
      setGoPage2(true);
    } else {
      setNoneClickedButNextPressed(true);
    }
  };

  function showNextButton() {
    if (goNextReview) {
      return (
        <button
          onClick={clickNextPage}
          className="rounded-sm bg-blue-300 px-4 py-2 mx-1 text-base h-11 w-[48.5%] hover:opacity-80"
          value="next"
        >
          Next
        </button>
      );
      // return (
      //   <Link
      //     to={"/questionnaire2/" + id}
      //     className="rounded-sm bg-blue-300 px-[20.5%] mx-1 py-3 text-bas text-center hover:opacity-80"
      //   >
      //     Next
      //   </Link>
      // );
    } else {
      return (
        <button
          onClick={clickNext}
          className="rounded-sm bg-blue-300 px-4 py-2 mx-1 text-base h-11 w-[48.5%] hover:opacity-80"
          value="next"
        >
          Next
        </button>
      );
    }
  }

  function showDebuggingPanel() {
    if (showDebuggingPanelDecider) {
      return (
        <div className="space-y-4 p-2">
          <p className="pl-1 pt-2 font-semibold">
            Select a word and indicate its classification using the buttons
            below
          </p>
          <div className="relative flex flex-col space-y-6">
            <button
              onClick={buttonClick}
              value="-0.25"
              className="w-[90%] bg-red-400 mx-1 self-center text-gray-800 py-4 hover:opacity-80 text-lg"
            >
              Deceptive
            </button>
            <button
              onClick={buttonClick}
              value="-0.08"
              className="w-[90%] bg-red-200 mx-1 self-center text-gray-800 py-4 hover:opacity-80 text-lg"
            >
              Somewhat deceptive
            </button>
            <button
              onClick={buttonClick}
              value="0"
              className="w-[90%] bg-gray-300 mx-1 self-center text-gray-800 py-4 hover:opacity-80 text-lg"
            >
              Neutral
            </button>
            <button
              onClick={buttonClick}
              value="0.08"
              className="w-[90%] bg-green-200 mx-1 self-center text-gray-800 py-4 hover:opacity-80 text-lg"
            >
              Somewhat genuine
            </button>
            <button
              onClick={buttonClick}
              value="0.25"
              className="w-[90%] bg-green-400 mx-1 self-center text-gray-800 py-4 hover:opacity-70 text-lg"
            >
              Genuine
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="space-y-4 p-2">
          <p className="pl-1 pt-2 font-bold">Select a word to use the panel</p>
          <p className="pl-1 pt-2 font-semibold opacity-40">
            Select a word and indicate its classification using the buttons
            below
          </p>
          <div className="relative flex flex-col space-y-6 opacity-40">
            <button
              onClick={buttonClick}
              value="-0.25"
              className="w-[90%] bg-red-400 mx-1 self-center text-gray-800 py-4 hover:opacity-80 text-lg"
            >
              Deceptive
            </button>
            <button
              onClick={buttonClick}
              value="-0.08"
              className="w-[90%] bg-red-200 mx-1 self-center text-gray-800 py-4 hover:opacity-80 text-lg"
            >
              Somewhat deceptive
            </button>
            <button
              onClick={buttonClick}
              value="0"
              className="w-[90%] bg-gray-300 mx-1 self-center text-gray-800 py-4 hover:opacity-80 text-lg"
            >
              Neutral
            </button>
            <button
              onClick={buttonClick}
              value="0.08"
              className="w-[90%] bg-green-200 mx-1 self-center text-gray-800 py-4 hover:opacity-80 text-lg"
            >
              Somewhat genuine
            </button>
            <button
              onClick={buttonClick}
              value="0.25"
              className="w-[90%] bg-green-400 mx-1 self-center text-gray-800 py-4 hover:opacity-70 text-lg"
            >
              Genuine
            </button>
          </div>
        </div>
      );
    }
  }

  const clickBack = () => {
    setLogString(logString + "bc: back\n");
    setGoPage2(false);
  };

  function noneClickedButNextPressedDisplayText() {
    if (noneClickedButNextPressed) {
      return (
        <p className="text-red-700 font-bold">
          You can not proceed without selecting wheter the review is correct or
          incorrect. Please do so before pressing 'Next'.
        </p>
      );
    }
  }

  if (id_order[current_review_counter] === 8) {
    return (
      <div className="flex space-x-2 divide-x-2">
        <div className="mx-auto flex max-w-6xl flex-col space-y-4">
          <h1 className="text-center text-xl">
            <span className="font-bold text-blue-400">
              Task: To show us that you are still reading every task carefully,
              (1) highlight "Breakfast" as 'Deceptive', "was" as 'Somewhat
              deceptive', "beautiful" as 'Somewhat genuine', and "space" as
              'Genuine'. (2) Indicate that the machine's prediction is 'Correct'
            </span>
          </h1>
          <br />
          <div className="flex divide-x space-x-5">
            <p className="text-justify">
              {
                <p
                  onClick={buttonSelect}
                  dangerouslySetInnerHTML={{
                    __html:
                      review_html_strings[id_order[current_review_counter]],
                  }}
                />
              }
            </p>
          </div>
          <hr></hr>
          <p className="pl-1 pt-2 font-semibold">
            Indicate whether the machine's prediction is correct
          </p>
          <div className="relative flex flex-row place-content-center">
            <button
              onClick={onClickCorrect}
              className={
                "w-[49.5%] text-gray-800 mx-1 " + checkClickedCorrect()
              }
            >
              Correct
            </button>
            <button
              onClick={onClickIncorrect}
              className={
                "w-[49.5%] text-gray-800 mx-1 " + checkClickedIncorrect()
              }
            >
              Incorrect
            </button>
          </div>
          <br />
          <hr />
          <br />
          <div className="flex relative justify-center">
            {showNextButtonAttentionCheck()}
          </div>
          {noneClickedButNextPressedDisplayText()}
        </div>
        {showDebuggingPanel()}
      </div>
    );
  } else {
    if (goPage2) {
      return (
        <div className="mx-auto flex max-w-6xl flex-col space-y-4">
          <div>{calculateResultsMessage()}</div>
          <div
            dangerouslySetInnerHTML={{
              __html: calculateListValues(),
            }}
          ></div>
          <div>{calculateMachinePredictionText()}</div>
          <div>
            <br />
            <button
              onClick={clickBack}
              className="rounded-sm bg-blue-300 px-4 py-2 mx-1 text-base h-11 w-[48.5%] hover:opacity-80"
              value="back"
            >
              Back
            </button>
            {showNextButton()}
          </div>
          {displayTimerText()}
        </div>
      );
    } else {
      return (
        <div className="mx-auto flex min-w-4xl max-w-6xl flex-col space-y-8 py-4">
          <button
            className="bg-orange-100 text-orange-900 active:bg-orange-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => setShowModal(true)}
          >
            Click here to view the guidelines for Deceptive Review Detection
          </button>
          {showModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                      <h3 className="text-3xl font-semibold">
                        Deceptive Review Detection Guidelines
                      </h3>
                      <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowModal(false)}
                      >
                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"></span>
                      </button>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                      <p className="text-lg font-bold">
                        Here are some guidelines that can help you finding
                        deceptive reviews:
                      </p>
                      <li className="text-lg">
                        Deceptive reviews tend to focus on aspects that are
                        external to the hotel being reviewed, e.g., husband,
                        busines, vacation.
                      </li>
                      <li className="text-lg">
                        Deceptive reviews tend to contain more emotional terms;
                        positive deceptive reviews are generally more positive
                        and negative deceptive reviews are more negative than
                        genuine reviews.
                      </li>
                      <li className="text-lg">
                        Genuine reviews tend to include more sensorial and
                        concrete language, in particular, genuine reviews are
                        more specific about spatial configurations, e.g., small,
                        bathroom, on, location.
                      </li>
                      <li className="text-lg">
                        Deceptive reviews tend to contain more verbs, e.g., eat,
                        sleep, stay.
                      </li>
                      <li className="text-lg">
                        Deceptive reviews tend to contain more superlatives,
                        e.g., cleanest, worst, best.
                      </li>
                      <li className="text-justify text-lg">
                        Deceptive reviews tend to contain more pre-determiners,
                        which are normally placed before an indefinite article +
                        adjective + noun, e.g., what a lovely day!
                      </li>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
                        type="button"
                        onClick={() => setShowModal(false)}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
          <div className="flex space-x-2 divide-x-2">
            <div className="mx-auto flex max-w-4xl flex-col space-y-4">
              <h1 className="text-center text-xl">
                <span className="font-bold text-blue-400">
                  Task: Correct the AI's advice by adjusting the highlights
                  using the panel to the right and indicating whether the AI is
                  correct. If you think the highlights are correct the way they
                  are presented to you, leave them as they are.
                </span>
              </h1>
              <br />
              <div className="flex divide-x space-x-5">
                <p className="text-justify">
                  {
                    <p
                      onClick={buttonSelect}
                      dangerouslySetInnerHTML={{
                        __html:
                          review_html_strings[id_order[current_review_counter]],
                      }}
                    />
                  }
                </p>
              </div>
              {showClassification()}
              <hr></hr>
              <p className="pl-1 pt-2 font-semibold">
                Indicate whether the machine's prediction is correct
              </p>
              <div className="relative flex flex-row place-content-center">
                <button
                  onClick={onClickCorrect}
                  className={
                    "w-[49.5%] text-gray-800 mx-1 " + checkClickedCorrect()
                  }
                >
                  Correct
                </button>
                <button
                  onClick={onClickIncorrect}
                  className={
                    "w-[49.5%] text-gray-800 mx-1 " + checkClickedIncorrect()
                  }
                >
                  Incorrect
                </button>
              </div>
              <br />
              <hr />
              <br />
              <div className="flex relative justify-center">
                <button
                  onClick={clickNextResult}
                  className="rounded-sm bg-blue-300 px-4 py-2 mx-1 text-base h-11 w-[99.5%] hover:opacity-80"
                  value="back"
                >
                  Next
                </button>
              </div>
              {noneClickedButNextPressedDisplayText()}
            </div>
            {showDebuggingPanel()}
          </div>
        </div>
      );
    }
  }
}

export default Debugging;
