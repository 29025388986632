import "./App.css";
import HomePage from "./HomePage";
import Assignments_Instructions1 from "./Assignments_Instructions1";
import Assignments_Instructions2 from "./Assignments_Instructions2";
import Assignments_Instructions_NT from "./Assignments_Instructions_NT";
import Assignments from "./Assignments";
import Assignments_p2 from "./Assignments_p2";
import Pre_Task_Questionnaire from "./Pre_Task_Questionnaire";
import Post_Task_Questionnaire from "./Post_Task_Questionnaire";
import Post_Task_Questionnaire2 from "./Post_Task_Questionnaire2";
import TrainingVideo from "./TrainingVideo";
import Debugging_Instructions from "./Debugging_Instructions";
import Debugging from "./Debugging";
import NASA_TLX from "./NASA_TLX";
import End_Screen from "./End_Screen";
import background from "./images/background.jpg";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage2 from "./HomePage2";
import Assignments_c from "./Assignments_c";
import Questionnaire3 from "./Questionnaire3";
import Assignments_Instructions3 from "./Assignments_Instructions3";
import End_Of_Control from "./End_Of_Control";
import Attention_Check_1_Control from "./Attention_Check_1_Control";
import Attention_Check_1_Main from "./Attention_Check_1_Main";
import Training_Phase_Introduction from "./Training_Phase_Introduction";
import Assignments_Pre_Check_Instructions from "./Assignments_Pre_Check_Instructions";
import Pre_Task_QuestionnaireATI from "./Pre_Task_Questionnaire_ATI";
import Post_Task_QuestionnaireTR from "./Post_Task_Questionnaire_TR";
import Post_Task_QuestionnaireTR2 from "./Post_Task_Questionnaire_TR2";
import Assignments_NT from "./Assignments_NT";
import ING_Demo from "./ING_Demo";

function App() {
  return (
    <BrowserRouter>
      <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 p-6">
        <img
          src={background}
          alt=""
          className="absolute top-1/2 left-1/2 max-w-none -translate-x-1/2 -translate-y-1/2 object-fill opacity-20 blur-3xl"
          width=""
        />
        <div className="absolute grayscale opacity-40 inset-0 bg-[url(./images/grid.svg)] bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]"></div>

        <div className="relative mx-auto max-w-6xl rounded-lg bg-white py-6 px-8 shadow-lg ring-1 ring-gray-100 divide-y">
          <div className="relative flex py-4 justify-center">
            <img
              className="relative flex h-20"
              src={require("./images/header_photo.jpg")}
              alt=""
            />
          </div>
          <Routes>
            <Route path="ing-demo" element={<ING_Demo />} />
            <Route path="user-port-:tog/" element={<HomePage />} />
            <Route path="control-port/" element={<HomePage2 />} />
            <Route
              path="/assignments_instructions1"
              element={<Assignments_Instructions1 />}
            />
            <Route
              path="/assignments_instructions2"
              element={<Assignments_Instructions2 />}
            />
            <Route
              path="/assignments_instructions_nt"
              element={<Assignments_Instructions_NT />}
            ></Route>
            <Route
              path="/assignments_instructions3/:id"
              element={<Assignments_Instructions3 />}
            />
            <Route
              path="/assignments_pci"
              element={<Assignments_Pre_Check_Instructions />}
            ></Route>
            <Route path="/assignments/" element={<Assignments />} />
            <Route path="/assignments_p2/" element={<Assignments_p2 />} />
            <Route path="/assignments_nt" element={<Assignments_NT />}></Route>
            <Route path="/assignments_c/:id" element={<Assignments_c />} />
            <Route
              path="/pre_task_questionnaire/"
              element={<Pre_Task_Questionnaire />}
            />
            <Route
              path="/pre_task_questionnaire_ati/"
              element={<Pre_Task_QuestionnaireATI />}
            ></Route>{" "}
            <Route
              path="/post_task_questionnaire_tr/"
              element={<Post_Task_QuestionnaireTR />}
            ></Route>
            <Route
              path="/post_task_questionnaire/"
              element={<Post_Task_Questionnaire />}
            />
            <Route
              path="/post_task_questionnaire_tr2"
              element={<Post_Task_QuestionnaireTR2 />}
            ></Route>
            <Route
              path="/post_task_questionnaire2/"
              element={<Post_Task_Questionnaire2 />}
            />
            <Route path="/questionnaire3/:id" element={<Questionnaire3 />} />
            <Route path="/nasa_tlx/" element={<NASA_TLX />} />
            <Route path="/end_of_control/:id" element={<End_Of_Control />} />
            <Route path="/training_video/" element={<TrainingVideo />} />
            <Route
              path="/debugging_instructions/"
              element={<Debugging_Instructions />}
            ></Route>
            <Route path="/debugging/" element={<Debugging />} />
            <Route path="/end_screen/" element={<End_Screen />} />
            <Route
              path="/pre_task_questionnaire_c/:id"
              element={<Attention_Check_1_Control />}
            />
            <Route
              path="/pre_task_questionnaire_1/"
              element={<Attention_Check_1_Main />}
            />
            <Route
              path="/training_phase_introduction/"
              element={<Training_Phase_Introduction />}
            />
          </Routes>
          <br />
          <hr />
          <br />

          <div className="p-2 outline outline-red-700">
            <h2 className="text-lg font-semibold">
              Listed here are some rules. If you fail to comply with these
              rules, you will be removed from the study.
            </h2>
            <div>
              <li>Complete every task as specified by the instructions.</li>
              <li>
                Do <mark className="font-bold bg-white">not</mark> use the{" "}
                <mark className="italian bg-white">back</mark> or{" "}
                <mark className="italian bg-white">forward</mark> buttons
                provided by your browser. Only use the buttons we provide you
                with.
              </li>
              <li>
                Do <mark className="font-bold bg-white">not</mark> reload the
                page.
              </li>
            </div>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
