import { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import "./Attention_Check_1_Main.css";

function Attention_Check_1_Main() {
  const location = useLocation();
  const id = location.state.id;
  const task_ordering_group = location.state.task_ordering_group;
  const batch_order = location.state.batch_order;
  const [attention_checks_failed, setAttentionChecksFailed] = useState(
    location.state.attention_checks_failed
  );

  const [attentionCheck, setAttentionCheck] = useState(""); //can adjust if needed
  const [attentionCheck2, setAttentionCheck2] = useState(""); //can adjust if needed

  function sendAttentionCheckInfo() {
    let atp = false;
    if (attentionCheck === "right") {
      atp = true;
    }
    var json_response = {};
    json_response.id = id;
    json_response.stage = "qualification_test";
    json_response.passed = atp;
    fetch(
      "https://thesis-crowdworker-app-backend-k5cvyplkba-ez.a.run.app/user-group/attention-checks/main",
      {
        mode: "no-cors",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(json_response),
      }
    );
  }

  function checkAttentionChecks() {
    if (attentionCheck === "right" && attentionCheck2 === "right") {
      return (
        <Link
          to={"/pre_task_questionnaire/"}
          onClick={sendAttentionCheckInfo}
          state={{
            attention_checks_failed: attention_checks_failed,
            id: id,
            task_ordering_group: task_ordering_group,
            batch_order: batch_order,
          }}
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold"
        >
          Next
        </Link>
      );
    } else {
      return (
        <a
          onClick={sendAttentionCheckInfo}
          href="https://app.prolific.co/submissions/complete?cc=C11CKFDX"
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold"
        >
          Next
        </a>
      );
    }
  }

  return (
    <div>
      <h1 className="text-xl text-center">
        <span className="font-bold text-blue-400 text-4xl">
          Qualification test
        </span>
      </h1>
      <br />
      <p className="text-lg font-bold text-center">
        Please ansewr the following questions.
      </p>
      <br />
      <label className="text-lg">
        <mark className="bg-white font-semibold">1.</mark> In this study, who
        are the deceptive reviews written by?
      </label>
      <br />
      <br />
      <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
        <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
          <div class="flex items-center pl-3">
            <input
              id="horizontal-list-radio-at-a"
              type="radio"
              value="a"
              onChange={(e) => {
                setAttentionCheck("wrong");
              }}
              required
              name="at"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
            ></input>
            <label
              for="horizontal-list-radio-at-a"
              class="py-3 ml-2 w-full text-md font-medium text-gray-900 dark:text-gray-300"
            >
              An AI system{" "}
            </label>
          </div>
        </li>
        <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
          <div class="flex items-center pl-3">
            <input
              id="horizontal-list-radio-at-b"
              type="radio"
              value="b"
              onChange={(e) => {
                setAttentionCheck("right");
              }}
              name="at"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
            ></input>
            <label
              for="horizontal-list-radio-at-b"
              class="py-3 ml-2 w-full text-md font-medium text-gray-900 dark:text-gray-300"
            >
              People without actual experience
            </label>
          </div>
        </li>
      </ul>
      <br></br>
      <label className="text-lg">
        <mark className="bg-white font-semibold">2.</mark> Indicate whether the
        following statement is true or false: "Guidelines are provided for
        finding deceptive reviews".
      </label>
      <br />
      <br />
      <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
        <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
          <div class="flex items-center pl-3">
            <input
              id="horizontal-list-radio-at2-a"
              type="radio"
              value="a"
              onChange={(e) => {
                setAttentionCheck2("right");
              }}
              required
              name="at2"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
            ></input>
            <label
              for="horizontal-list-radio-at2-a"
              class="py-3 ml-2 w-full text-md font-medium text-gray-900 dark:text-gray-300"
            >
              True{" "}
            </label>
          </div>
        </li>
        <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
          <div class="flex items-center pl-3">
            <input
              id="horizontal-list-radio-at2-b"
              type="radio"
              value="b"
              onChange={(e) => {
                setAttentionCheck2("wrong");
              }}
              name="at2"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
            ></input>
            <label
              for="horizontal-list-radio-at2-b"
              class="py-3 ml-2 w-full text-md font-medium text-gray-900 dark:text-gray-300"
            >
              False
            </label>
          </div>
        </li>
      </ul>
      <br />
      <div className="flex">{checkAttentionChecks()}</div>
    </div>
  );
}

export default Attention_Check_1_Main;
