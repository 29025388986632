import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Pre_Task_Questionnaire() {
  const location = useLocation();
  const id = location.state.id;
  const task_ordering_group = location.state.task_ordering_group;
  const batch_order = location.state.batch_order;
  const [attention_checks_failed, setAttentionChecksFailed] = useState(
    location.state.attention_checks_failed
  );

  //FINISH THE ATTENTION CHECK
  const [attentionCheck, setAttentionCheck] = useState(""); //can adjust if needed
  const [answer3, setAnswer3] = useState("");
  const [answer5, setAnswer5] = useState("");
  const [answer12, setAnswer12] = useState("");
  const [answer17, setAnswer17] = useState("");
  const [answer18, setAnswer18] = useState("");

  function displayAnswers() {
    return (
      "Attention check: " +
      attentionCheck +
      "\nAnswer3: " +
      answer3 +
      "\nAnswer5: " +
      answer5 +
      "\nAnswer12: " +
      answer12 +
      "\nAnswer17: " +
      answer17 +
      "\nAnswer18: " +
      answer18
    );
  }

  // useEffect(() => {
  //   console.log(displayAnswers());
  // }, [answer18]);

  useEffect(() => {
    if (allQuestionsHaveBeenAnswered()) {
      setShowQuestionsText(false);
    }
  }, [answer3, answer5, answer12, answer17, answer18]);

  const navigate = useNavigate();

  // const [notAllQuestionsHaveBeenAnswered, setNotAllQuestionsHaveBeenAnswered] =
  //   useState(true);

  const countDownValue = 0;
  const [countDown, setCountDown] = useState(countDownValue);
  const [showTimerText, setShowTimerText] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
      } else {
        setShowTimerText(false);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);

  function displayTimerText() {
    if (showTimerText) {
      return (
        <p className="text-red-500 font-bold">
          Don't hurry your answers. We expect you to spend at least 10 seconds
          on this questionnaire.
        </p>
      );
    } else {
      return;
    }
  }

  const [showQuestionsText, setShowQuestionsText] = useState(false);

  function attentionCheckHasBeenAnswered() {
    if (attentionCheck !== "") {
      return true;
    } else {
      return false;
    }
  }

  function attentionCheckIsCorrect() {
    if (attentionCheck === "right") {
      return true;
    } else {
      return false;
    }
  }

  function allQuestionsHaveBeenAnswered() {
    if (
      answer3 !== "" &&
      answer5 !== "" &&
      answer12 !== "" &&
      answer17 !== "" &&
      answer18 !== ""
    ) {
      return true;
    } else {
      return false;
    }
  }

  function sendAttentionCheckInfo(atp) {
    var json_response = {};
    json_response.id = id;
    json_response.stage = "pre_task_questionnaire";
    json_response.passed = atp;
    fetch(
      "https://thesis-crowdworker-app-backend-k5cvyplkba-ez.a.run.app/user-group/attention-checks/main",
      {
        mode: "no-cors",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(json_response),
      }
    );
  }

  function clickNext() {
    let atp = false;
    if (attentionCheck === "right") {
      atp = true;
    }
    var json_response = {};
    json_response.id = id;
    json_response.stage = "pre_task_questionnaire";
    json_response.passed = atp;
    fetch(
      "https://thesis-crowdworker-app-backend-k5cvyplkba-ez.a.run.app/user-group/attention-checks/main",
      {
        mode: "no-cors",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(json_response),
      }
    );

    let json_response2 = {};
    json_response2.id = id;
    json_response2.question3 = answer3;
    json_response2.question5 = answer5;
    json_response2.question12 = answer12;
    json_response2.question17 = answer17;
    json_response2.question18 = answer18;

    fetch(
      "https://thesis-crowdworker-app-backend-k5cvyplkba-ez.a.run.app/user-group/pre-task-questionnaire-tia",
      {
        mode: "no-cors",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(json_response2),
      }
    );

    // CODE TO SEND ALL QUESTION INFO TO THE DATABASE
    navigate("/pre_task_questionnaire_ati/", {
      state: {
        attention_checks_failed: attention_checks_failed,
        id: id,
        task_ordering_group: task_ordering_group,
        batch_order: batch_order,
      },
    });
  }

  function clickNextNotAllQuestionsAnswered() {
    setShowQuestionsText(true);
  }

  function clickNextTimerNotZero() {
    setShowTimerText(true);
  }

  function displayQuestionsText() {
    if (showQuestionsText) {
      return (
        <p className="text-red-500 font-bold">
          Please answer all questions before pressing next.
        </p>
      );
    } else {
      return;
    }
  }

  function showNextButton() {
    if (attentionCheckHasBeenAnswered() && attentionCheckIsCorrect()) {
      if (countDown === 0) {
        if (allQuestionsHaveBeenAnswered()) {
          return (
            <button
              onClick={clickNext}
              className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
              value="next"
            >
              Next
            </button>
          );
        } else {
          return (
            <button
              onClick={clickNextNotAllQuestionsAnswered}
              className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
              value="next"
            >
              Next
            </button>
          );
        }
      } else {
        return (
          <button
            onClick={clickNextTimerNotZero}
            className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
            value="next"
          >
            Next
          </button>
        );
      }
    } else {
      return (
        <a
          onClick={clickWrong}
          href="https://app.prolific.co/submissions/complete?cc=C11CKFDX"
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
        >
          Next
        </a>
      );
    }
  }

  function clickWrong() {
    sendAttentionCheckInfo(false);
  }

  return (
    <div className="m-2">
      <form className="relative flex flex-col space-y-4">
        <p className="text-2xl text-center text-blue-400 font-bold">
          Please answer the following questions truthfully.
        </p>
        <p className="text-lg font-bold">
          In the Deceptive Review Detection task, you will receive advice from
          an AI system along with highlights in the review that explain its
          advice. Consider interacting with such an AI system while answering
          the following questions.
        </p>
        <hr />
        <br />
        <label className="text-lg">
          <mark className="bg-white font-bold">1.</mark> I already know similar
          systems.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q3-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswer3(e.target.value);
                }}
                name="q3"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                required
              ></input>
              <label
                for="horizontal-list-radio-q3-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q3-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswer3(e.target.value);
                }}
                name="q3"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q3-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q3-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswer3(e.target.value);
                }}
                name="q3"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q3-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Neither disagree nor agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q3-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswer3(e.target.value);
                }}
                name="q3"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q3-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q3-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswer3(e.target.value);
                }}
                name="q3"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q3-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-bold">2.</mark> I have already used
          similar systems.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q17-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswer17(e.target.value);
                }}
                required
                name="q17"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q17-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q17-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswer17(e.target.value);
                }}
                name="q17"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q17-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q17-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswer17(e.target.value);
                }}
                name="q17"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q17-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Neither disagree nor agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q17-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswer17(e.target.value);
                }}
                name="q17"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q17-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q17-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswer17(e.target.value);
                }}
                name="q17"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q17-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-bold">3.</mark> One should be careful
          with unfamiliar automated systems.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q5-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswer5(e.target.value);
                }}
                required
                name="q5"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q5-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q5-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswer5(e.target.value);
                }}
                name="q5"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q5-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q5-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswer5(e.target.value);
                }}
                name="q5"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q5-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Neither disagree nor agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q5-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswer5(e.target.value);
                }}
                name="q5"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q5-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q5-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswer5(e.target.value);
                }}
                name="q5"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q5-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-bold">4.</mark> Please select the
          button labeled "Rather agree".
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-at-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAttentionCheck("wrong");
                }}
                required
                name="at"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-at-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-at-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAttentionCheck("wrong");
                }}
                name="at"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-at-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-at-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAttentionCheck("wrong");
                }}
                name="at"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-at-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Neither disagree nor agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-at-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAttentionCheck("right");
                }}
                name="at"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-at-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-at-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAttentionCheck("wrong");
                }}
                name="at"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-at-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-bold">5.</mark> I rather trust a system
          than I mistrust it.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q12-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswer12(e.target.value);
                }}
                required
                name="q12"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q12-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q12-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswer12(e.target.value);
                }}
                name="q12"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q12-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q12-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswer12(e.target.value);
                }}
                name="q12"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q12-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Neither disagree nor agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q12-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswer12(e.target.value);
                }}
                name="q12"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q12-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q12-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswer12(e.target.value);
                }}
                name="q12"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q12-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-bold">6.</mark> Automated systems
          generally work well.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q18-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswer18(e.target.value);
                }}
                required
                name="q18"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q18-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q18-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswer18(e.target.value);
                }}
                name="q18"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q18-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q18-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswer18(e.target.value);
                }}
                name="q18"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q18-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Neither disagree nor agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q18-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswer18(e.target.value);
                }}
                name="q18"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q18-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q18-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswer18(e.target.value);
                }}
                name="q18"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q18-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly agree
              </label>
            </div>
          </li>
        </ul>
      </form>
      <br />
      <br />
      <div className="flex">{showNextButton()}</div>
      <div className="flex">{displayQuestionsText()}</div>
      <br />
      <div className="flex">{displayTimerText()}</div>
    </div>
  );
}

export default Pre_Task_Questionnaire;
