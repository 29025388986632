import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import training_video from "./videos/training_phase_video_with_subtitles.mp4";

function TrainingVideo() {
  const location = useLocation();
  const id = location.state.id;
  const task_ordering_group = location.state.task_ordering_group;
  const batch_order = location.state.batch_order;
  const [attention_checks_failed, setAttentionChecksFailed] = useState(
    location.state.attention_checks_failed
  );

  const navigate = useNavigate();

  const countDownValue = 225;

  const [countDown, setCountDown] = useState(countDownValue);
  const [showTimerText, setShowTimerText] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
      } else {
        setShowTimerText(false);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);

  function displayTimerText() {
    if (showTimerText) {
      return (
        <p className="text-red-500 font-bold">
          Please watch the video in full before proceeding to the next page.
        </p>
      );
    } else {
      return;
    }
  }

  function onClick() {
    if (countDown === 0) {
      navigate("/debugging_instructions/", {
        state: {
          attention_checks_failed: attention_checks_failed,
          id: id,
          task_ordering_group: task_ordering_group,
          batch_order: batch_order,
        },
      });
    } else {
      setShowTimerText(true);
    }
  }

  return (
    <div className="mx-auto flex max-w-4xl flex-col space-y-4">
      <h1 className="text-xl text-center">
        <span className="font-bold text-blue-400 text-4xl">
          Watch the video carefully, then proceed.
        </span>
      </h1>
      <br />
      <video controls>
        <source src={training_video} type="video/mp4" />
      </video>
      <button
        // to={"/debugging_instructions/" + id}
        onClick={onClick}
        className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
      >
        Next
      </button>
      {displayTimerText()}
    </div>
  );
}

export default TrainingVideo;
