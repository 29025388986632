import "./End_Screen.css";

function End_Screen() {
  return (
    <div className="flex flex-col mx-auto max-w-6xl space-y-6 py-4 m-2">
      <h1 className="text-3xl text-blue-300 font-bold">
        Thanks for participating in our study! Click the button to exit the
        page!
      </h1>
      <a
        href="https://app.prolific.co/submissions/complete?cc=CFKEGVNZ"
        className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
      >
        Next
      </a>
    </div>
  );
}

export default End_Screen;
