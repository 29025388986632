import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function NASA_TLX() {
  const location = useLocation();
  const id = location.state.id;
  const task_ordering_group = location.state.task_ordering_group;
  const batch_order = location.state.batch_order;

  const [attention_checks_failed, setAttentionChecksFailed] = useState(
    location.state.attention_checks_failed
  );

  const [answer1, setAnswer1] = useState(
    "Use the slider to indicate the appropriate amount of "
  );
  const [answer2, setAnswer2] = useState(
    "Use the slider to indicate the appropriate amount of "
  );
  const [answer3, setAnswer3] = useState(
    "Use the slider to indicate the appropriate amount of "
  );
  const [answer4, setAnswer4] = useState(
    "Use the slider to indicate the appropriate amount of "
  );
  const [answer5, setAnswer5] = useState(
    "Use the slider to indicate the appropriate amount of "
  );
  const [answer6, setAnswer6] = useState(
    "Use the slider to indicate the appropriate amount of "
  );

  const [confirmation, setConfirmation] = useState(false);

  function displayAnswers() {
    return (
      "\nAnswer1: " +
      answer1 +
      "\nAnswer2: " +
      answer2 +
      "\nAnswer3: " +
      answer3 +
      "\nAnswer4: " +
      answer4 +
      "\nAnswer5: " +
      answer5 +
      "\nAnswer6: " +
      answer6 +
      "\nConfirmation: " +
      confirmation
    );
  }
  
  // useEffect(() => {
  //   console.log(displayAnswers());
  // }, [confirmation]);

  useEffect(() => {
    if (allQuestionsHaveBeenAnswered()) {
      setShowQuestionsText(false);
    }
  }, [answer1, answer2, answer3, answer4, answer5, answer6, confirmation]);

  const navigate = useNavigate();

  // const [notAllQuestionsHaveBeenAnswered, setNotAllQuestionsHaveBeenAnswered] =
  //   useState(true);

  const countDownValue = 0;
  const [countDown, setCountDown] = useState(countDownValue);
  const [showTimerText, setShowTimerText] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
      } else {
        setShowTimerText(false);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);

  function displayTimerText() {
    if (showTimerText) {
      return (
        <p className="text-red-500 font-bold">
          Don't hurry your answers. We expect you to spend at least 20 seconds
          on this questionnaire.
        </p>
      );
    } else {
      return;
    }
  }

  const [showQuestionsText, setShowQuestionsText] = useState(false);

  function allQuestionsHaveBeenAnswered() {
    if (
      answer1 !== "Use the slider to indicate the appropriate amount of " &&
      answer2 !== "Use the slider to indicate the appropriate amount of " &&
      answer3 !== "Use the slider to indicate the appropriate amount of " &&
      answer4 !== "Use the slider to indicate the appropriate amount of " &&
      answer5 !== "Use the slider to indicate the appropriate amount of " &&
      answer6 !== "Use the slider to indicate the appropriate amount of " &&
      confirmation
    ) {
      return true;
    } else {
      return false;
    }
  }

  function clickNext() {
    // CODE TO SEND ALL QUESTION INFO TO THE DATABASE
    var json_response = {};
    json_response.id = id;
    json_response.question1 = answer1;
    json_response.question2 = answer2;
    json_response.question3 = answer3;
    json_response.question4 = answer4;
    json_response.question5 = answer5;
    json_response.question6 = answer6;

    fetch(
      "https://thesis-crowdworker-app-backend-k5cvyplkba-ez.a.run.app/user-group/nasa-tlx",
      {
        mode: "no-cors",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(json_response),
      }
    );

    navigate("/assignments_instructions2/", {
      state: {
        attention_checks_failed: attention_checks_failed,
        id: id,
        task_ordering_group: task_ordering_group,
        batch_order: batch_order,
      },
    });
  }

  function clickNextNotAllQuestionsAnswered() {
    setShowQuestionsText(true);
  }

  function clickNextTimerNotZero() {
    setShowTimerText(true);
  }

  function displayQuestionsText() {
    if (showQuestionsText) {
      return (
        <p className="text-red-500 font-bold">
          Please answer all questions before pressing next.
        </p>
      );
    } else {
      return;
    }
  }

  function showNextButton() {
    if (countDown === 0) {
      if (allQuestionsHaveBeenAnswered()) {
        return (
          <button
            onClick={clickNext}
            className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
            value="next"
          >
            Next
          </button>
        );
      } else {
        return (
          <button
            onClick={clickNextNotAllQuestionsAnswered}
            className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
            value="next"
          >
            Next
          </button>
        );
      }
    } else {
      return (
        <button
          onClick={clickNextTimerNotZero}
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
          value="next"
        >
          Next
        </button>
      );
    }
  }

  return (
    <div className="m-2">
      <form className="relative flex flex-col space-y-4">
        <p className="text-2xl text-center text-blue-400 font-bold">
          Please answer the following questions truthfully.
        </p>
        <br />
        <p className="text-lg text-center font-bold">
          The answers assess the work load of the debugging assignment you just
          finished. Your answers are measured on a scale of -7 points (very low)
          to 7 points (very high)
        </p>
        <hr />
        <br></br>
        <label className="text-lg">
          <mark className="bg-white font-bold">1.</mark> How mentally demanding
          was the task?
        </label>
        <label
          for="q1"
          class="block mb-2 text-gray-800 dark:text-gray-300 text-lg font-semibold ml-8"
        >
          {answer1 + " points"}
        </label>
        <input
          id="q1"
          type="range"
          min="-7"
          max="7"
          value={answer1}
          onChange={(e) => {
            setAnswer1(e.target.value);
          }}
          class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
        ></input>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-bold">2.</mark> How physically
          demanding was the task?
        </label>
        <label
          for="q2"
          class="block mb-2 text-gray-800 dark:text-gray-300 text-lg font-semibold ml-8"
        >
          {answer2 + " points"}
        </label>
        <input
          id="q2"
          type="range"
          min="-7"
          max="7"
          value={answer2}
          onChange={(e) => {
            setAnswer2(e.target.value);
          }}
          class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
        ></input>
        <label className="text-lg">
          <mark className="bg-white font-bold">3.</mark> How hurried or rushed
          was the pace of the task?
        </label>
        <label
          for="q3"
          class="block mb-2 text-gray-800 dark:text-gray-300 text-lg font-semibold ml-8"
        >
          {answer3 + " points"}
        </label>
        <input
          id="q3"
          type="range"
          min="-7"
          max="7"
          value={answer3}
          onChange={(e) => {
            setAnswer3(e.target.value);
          }}
          class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
        ></input>
        <label className="text-lg">
          <mark className="bg-white font-bold">4.</mark> How successful were you
          in accomplishing what you were asked to do?
        </label>
        <label
          for="q4"
          class="block mb-2 text-gray-800 dark:text-gray-300 text-lg font-semibold ml-8"
        >
          {answer4 + " points"}
        </label>
        <input
          id="q4"
          type="range"
          min="-7"
          max="7"
          value={answer4}
          onChange={(e) => {
            setAnswer4(e.target.value);
          }}
          class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
        ></input>
        <label className="text-lg">
          <mark className="bg-white font-bold">5.</mark> How hard did you have
          to work to accomplish your level of performance?
        </label>
        <label
          for="q5"
          class="block mb-2 text-gray-800 dark:text-gray-300 text-lg font-semibold ml-8"
        >
          {answer5 + " points"}
        </label>
        <input
          id="q5"
          type="range"
          min="-7"
          max="7"
          value={answer5}
          onChange={(e) => {
            setAnswer5(e.target.value);
          }}
          class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
        ></input>
        <label className="text-lg">
          <mark className="bg-white font-bold">6.</mark> How insecure,
          discouraged, irritated, stressed, and annoyed were you?
        </label>
        <label
          for="q6"
          class="block mb-2 text-gray-800 dark:text-gray-300 text-lg font-semibold ml-8"
        >
          {answer6 + " points"}
        </label>
        <input
          id="q6"
          type="range"
          min="-7"
          max="7"
          value={answer6}
          onChange={(e) => {
            setAnswer6(e.target.value);
          }}
          class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
        ></input>
        <label className="text-lg">
          <mark className="bg-white font-bold">
            Please confirm your answers by checking the checkbox.
          </mark>
        </label>
        <div class="flex items-center">
          <input
            id="confirmation"
            type="checkbox"
            onChange={(e) => {
              setConfirmation(!confirmation);
            }}
            class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          ></input>
          <label
            for="Confirmation"
            class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            I confirm my answers.
          </label>
        </div>
      </form>
      <br />
      <br />
      <div className="flex">{showNextButton()}</div>
      <div className="flex">{displayQuestionsText()}</div>
      <br />
      <div className="flex">{displayTimerText()}</div>
    </div>
  );
}

export default NASA_TLX;
