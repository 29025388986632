import "./End_Of_Control.css";
import { Link, useParams } from "react-router-dom";

function End_Of_Control() {
  const { id } = useParams();

  return (
    <div className="flex flex-col mx-auto max-w-6xl space-y-6 py-4 m-2">
      <h1 className="text-xl text-center text-blue-400 font-bold">
        You are done with your tasks.
      </h1>
      <br />
      <a
        href="https://app.prolific.co/submissions/complete?cc=CFYSAQSS"
        className="rounded-sm bg-blue-400 px-8 py-4 text-base mx-auto text-gray-900 font-semibold"
      >
        Finish
      </a>
    </div>
  );
}

export default End_Of_Control;
