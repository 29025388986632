import "./HomePage.css";
import { Link, useParams, useSearchParams } from "react-router-dom";

function HomePage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("PROLIFIC_PID");

  const params = useParams();
  const task_ordering_group = parseInt(params.tog);

  //The batch order will decide which batch will be presented to the user. If it is true, batch 2 will go first, if it is false, batch 1 will go first.
  const batch_order =
    Math.floor(Math.random() * (1 - 0 + 1) + 0) === 0 ? true : false;

  function onClick() {
    let batch1, batch2;

    if (batch_order) {
      batch1 = 2;
      batch2 = 1;
    } else {
      batch1 = 1;
      batch2 = 2;
    }

    let json_response = {
      task_ordering_group: task_ordering_group,
      id: id,
      b1: batch1,
      b2: batch2,
    };

    fetch(
      //send to backend
      "https://thesis-crowdworker-app-backend-k5cvyplkba-ez.a.run.app/user-group/user-data",
      {
        mode: "no-cors",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(json_response),
      }
    );
  }

  return (
    <div className="flex flex-col mx-auto max-w-6xl space-y-6 py-4 m-2">
      <h1 className="text-xl text-center">
        <span className="font-bold text-blue-400 text-4xl">Welcome</span>
      </h1>
      <p className="text-justify text-lg">
        In this study, you will be asked to perform a series of tasks based on
        hotel reviews with the support of AI assistants. There are several
        stages. When necessary, you will be presented with instructions and
        examples to guide you through the next stage. The study will take around{" "}
        <mark className="bg-white font-semibold">30 minutes</mark>.
      </p>
      <br />
      <Link
        to={"/assignments_pci/"}
        onClick={onClick}
        state={{
          attention_checks_failed: 0,
          task_ordering_group: task_ordering_group,
          id: id,
          batch_order: batch_order,
        }}
        className="rounded-sm bg-blue-400 px-4 py-4 text-base mx-auto text-gray-900 font-semibold"
      >
        CONTINUE
      </Link>
    </div>
  );
}

export default HomePage;
