import "./Debugging_Instructions.css";
import { Link, useLocation, useParams } from "react-router-dom";
import debugging_tutorial from "./videos/debugging_tutorial.mp4";
import { useEffect, useState } from "react";

function Debugging_Instructions() {
  const location = useLocation();
  const id = location.state.id;
  const task_ordering_group = location.state.task_ordering_group;
  const batch_order = location.state.batch_order;
  const [attention_checks_failed, setAttentionChecksFailed] = useState(
    location.state.attention_checks_failed
  );

  const countDownValue = 0;
  const [countDown, setCountDown] = useState(countDownValue);
  const [showTimerText, setShowTimerText] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
      } else {
        setShowTimerText(false);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);

  function displayTimerText() {
    if (showTimerText) {
      return (
        <p className="text-red-500 font-bold">
          Please read the instructions and watch the video before you continue.
        </p>
      );
    } else {
      return;
    }
  }

  function showNextButton() {
    if (countDown === 0) {
      return (
        <Link
          to={"/debugging/"}
          state={{
            attention_checks_failed: attention_checks_failed,
            id: id,
            task_ordering_group: task_ordering_group,
            batch_order: batch_order,
          }}
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900"
        >
          Next
        </Link>
      );
    } else {
      return (
        <button
          onClick={() => {
            setShowTimerText(true);
          }}
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900"
          value="next"
        >
          Next
        </button>
      );
    }
  }

  return (
    <div className="flex flex-col mx-auto max-w-6xl space-y-6 py-4 m-2">
      <h1 className="text-xl text-center">
        <span className="font-bold text-blue-400 text-4xl">
          Debugging Instructions
        </span>
      </h1>
      <p className="text-justify text-lg">
        Well done, you finished the first stage! Are you good at identifying
        when AI advice is correct? Let's test it! In this stage, you have{" "}
        <mark className="bg-white font-bold">8 tasks</mark> to complete. Given
        below are instructions that will explain what is expected from each
        task. A video demonstrates what the process looks like using an example
        task.
      </p>
      <p className="text-justify text-lg">
        The task that you will be presented with is similar to{" "}
        <mark className="bg-white italic">Deception Detection</mark>. You will,
        again, be shown a variety of hotel reviews. Now, however, you are asked
        to check whether the AI advice is correct. Additionally, you have to
        change the highlights whenever you think that is necessary. As a
        reminder: a <mark className="bg-white font-bold">deceptive </mark>
        hotel review is one that has been made up. The person writing such a
        review has never visited the hotel and therefore does not write about
        actual experiences at the hotel. A{" "}
        <mark className="bg-white font-bold">genuine</mark> review is the exact
        opposite. The writers have visited the hotel and write about their
        experiences there. Highlighted words indicate whether the machine thinks
        that word makes a review more{" "}
        <mark className="bg-green-300">genuine</mark> or{" "}
        <mark className="bg-red-300">deceptive</mark>, depending on the color.
        Deeper colors are more indicative than lighter ones. In each task, the
        10 most important words are highlighted.
      </p>
      <div>
        <p className="text-lg font-bold">
          Here are some guidelines that can help you finding deceptive reviews:
        </p>
        <li className="text-lg">
          Deceptive reviews tend to focus on aspects that are external to the
          hotel being reviewed, e.g., husband, busines, vacation.
        </li>
        <li className="text-lg">
          Deceptive reviews tend to contain more emotional terms; positive
          deceptive reviews are generally more positive and negative deceptive
          reviews are more negative than genuine reviews.
        </li>
        <li className="text-lg">
          Genuine reviews tend to include more sensorial and concrete language,
          in particular, genuine reviews are more specific about spatial
          configurations, e.g., small, bathroom, on, location.
        </li>
        <li className="text-lg">
          Deceptive reviews tend to contain more verbs, e.g., eat, sleep, stay.
        </li>
        <li className="text-lg">
          Deceptive reviews tend to contain more superlatives, e.g., cleanest,
          worst, best.
        </li>
        <li className="text-justify text-lg">
          Deceptive reviews tend to contain more pre-determiners, which are
          normally placed before an indefinite article + adjective + noun, e.g.,
          what a lovely day!
        </li>
      </div>
      <p className="text-justify text-lg font-bold">
        Steps for completing the tasks:
      </p>
      <p className="text-justify text-lg">
        <mark className="bg-white font-bold">Step 1 </mark> You are presented
        with a review. In the review,&nbsp;
        <mark className="bg-white font-semibold">words </mark> that the AI
        system deems to be genuine or deceptive are highlighted in green or red
        respectively, alongside its advice.
      </p>
      <p className="text-justify text-lg">
        <mark className="bg-white font-bold">Step 2 </mark> Have a look at these
        highlights and decide if they correctly reflect the guidelines for
        identifying deceptive reviews (i.e. check whether they have the right
        color and intensity).
      </p>
      <p className="text-justify text-lg">
        <mark className="bg-white font-bold">Step 3 </mark> If you think a word
        needs a different highlight, click on that word. A border around the
        highlight will indicate that you have selected it. Also, a panel on the
        right side will become visible, allowing you to pick the correct
        highlight for that word.
      </p>
      <p className="text-justify text-lg">
        <mark className="bg-white font-bold">Step 4 </mark> Indicate whether you
        think the AI system has provided you with the correct advice.
      </p>
      <p className="text-justify text-lg">
        <mark className="bg-white font-bold">Step 5 </mark> Click the{" "}
        <mark className="bg-white italic">next</mark>-button. You will be
        presented with insights on your performance in the debugging task.
      </p>
      <p className="text-justify text-lg">
        <mark className="bg-white font-bold">Step 6 </mark> If you want to
        review or correct your answers, click the{" "}
        <mark className="bg-white italic">back</mark>-button. This will prompt
        you to the previous page. After reviewing/updating your answers, proceed
        to the next task.
      </p>
      <br />
      <p className="text-justify text-lg font-bold">
        Below, you will find an instructional video demonstrating these steps.
      </p>
      <video controls>
        <source src={debugging_tutorial} type="video/mp4" />
      </video>
      {showNextButton()}
      {displayTimerText()}
    </div>
  );
}

export default Debugging_Instructions;
