import "./Assignments_Instructions2.css";
import { Link, useLocation, useParams } from "react-router-dom";
import tasks_tutorial from "./videos/tasks_tutorial.mp4";
import { useEffect, useState } from "react";

function Assignments_Instructions2() {
  const location = useLocation();
  const id = location.state.id;
  const task_ordering_group = location.state.task_ordering_group;
  const batch_order = location.state.batch_order;
  const [attention_checks_failed, setAttentionChecksFailed] = useState(
    location.state.attention_checks_failed
  );

  const countDownValue = 0;
  const [countDown, setCountDown] = useState(countDownValue);
  const [showTimerText, setShowTimerText] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
      } else {
        setShowTimerText(false);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);

  function displayTimerText() {
    if (showTimerText) {
      return (
        <p className="text-red-500 font-bold">
          Please read the instructions and watch the video before you continue.
        </p>
      );
    } else {
      return;
    }
  }

  function showNextButton() {
    if (countDown === 0) {
      return (
        <Link
          to={"/assignments_p2/"}
          state={{
            attention_checks_failed: attention_checks_failed,
            id: id,
            task_ordering_group: task_ordering_group,
            batch_order: batch_order,
          }}
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900"
        >
          Next
        </Link>
      );
    } else {
      return (
        <button
          onClick={() => {
            setShowTimerText(true);
          }}
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900"
          value="next"
        >
          Next
        </button>
      );
    }
  }

  return (
    <div className="flex flex-col mx-auto max-w-6xl space-y-6 py-4 m-2">
      <h1 className="text-xl text-center">
        <span className="font-bold text-blue-400 text-4xl">
          Task Introduction: Deceptive Review Detection
        </span>
      </h1>
      <br />
      <p className="text-justify text-lg">
        Once again, the task that you will be presented with is called{" "}
        <mark className="bg-white italic">Deceptive Review Detection</mark>. You
        will be shown a variety of hotel reviews. Your job is to decide which
        ones are <mark className="bg-white font-bold">deceptive </mark>
        and which ones are <mark className="bg-white font-bold">genuine</mark>.
        A <mark className="bg-white font-bold">deceptive </mark> hotel review is
        one that has been made up. The person writing such a review has never
        visited the hotel and therefore does not write about actual experiences
        at the hotel. A <mark className="bg-white font-bold">genuine</mark>{" "}
        review is the exact opposite. The writers have visited the hotel and
        write about their experiences there.
      </p>
      <p className="text-justify text-lg">
        Each review will be shown to you two times. The first time, you will
        only be shown the review in plain text. The second time, you will be
        shown that same review along with advice from an AI system. The advice
        from the AI system is explained with highlights in the review that
        support it. Highlights occur on single words and indicate how the AI
        system assesses the contribution of the word to a review that is more{" "}
        <mark className="bg-green-300">genuine</mark> or{" "}
        <mark className="bg-red-300">deceptive</mark>, depending on the color.
        Deeper colors are more indicative than lighter ones.
      </p>
      <div>
        <p className="text-lg font-bold">
          Here are some guidelines that can help you finding deceptive reviews:
        </p>
        <li className="text-lg">
          Deceptive reviews tend to focus on aspects that are external to the
          hotel being reviewed, e.g., husband, busines, vacation.
        </li>
        <li className="text-lg">
          Deceptive reviews tend to contain more emotional terms; positive
          deceptive reviews are generally more positive and negative deceptive
          reviews are more negative than genuine reviews.
        </li>
        <li className="text-lg">
          Genuine reviews tend to include more sensorial and concrete language,
          in particular, genuine reviews are more specific about spatial
          configurations, e.g., small, bathroom, on, location.
        </li>
        <li className="text-lg">
          Deceptive reviews tend to contain more verbs, e.g., eat, sleep, stay.
        </li>
        <li className="text-lg">
          Deceptive reviews tend to contain more superlatives, e.g., cleanest,
          worst, best.
        </li>
        <li className="text-justify text-lg">
          Deceptive reviews tend to contain more pre-determiners, which are
          normally placed before an indefinite article + adjective + noun, e.g.,
          what a lovely day!
        </li>
      </div>
      <br />
      <p className="text-justify text-lg font-bold">
        Steps for completing the tasks:
      </p>
      <p className="text-justify text-lg">
        <mark className="bg-white font-bold">Step 1 </mark> You are presented
        with a review. Read it carefully and decide whether it is deceptive or
        genuine. Also indicate your confidence in the decision.
      </p>
      <p className="text-justify text-lg">
        <mark className="bg-white font-bold">Step 2 </mark> When you have
        decided, lock your choice in by using the buttons and click on the{" "}
        <mark className="bg-white italic">next</mark>-button.
      </p>
      <p className="text-justify text-lg">
        <mark className="bg-white font-bold">Step 3 </mark> You are presented
        with the same review, along with supporting highlights and advice from
        an AI system. By considering the highlights and AI advice, decide
        whether the review is genuine or deceptive. Once again indicate your
        confidence in your final decision.
      </p>
      <p className="text-justify text-lg">
        <mark className="bg-white font-bold">Step 4 </mark> When you have
        decided, lock your choice in by using the buttons and click on the{" "}
        <mark className="bg-white italic">next</mark>-button to move forward to
        the next page.
      </p>
      <p className="text-justify text-lg font-semibold text-red-500">
        If you get stuck at any of these steps, you can use the guidelines (by
        clicking on the button at the top of the interface) to help you figure
        out the right answer.
      </p>
      <br />
      <p className="text-justify text-lg font-bold">
        Below, you will find an instructional video demonstrating these steps.
      </p>

      <video controls>
        <source src={tasks_tutorial} type="video/mp4" />
      </video>
      {showNextButton()}
      {displayTimerText()}
    </div>
  );
}

export default Assignments_Instructions2;
