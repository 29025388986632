import "./Assignments.css";
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import review_1 from "./reviews_p1.json";
import review_2 from "./reviews_p2.json";

function Assignments() {
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [allDataSent, setAllDataSent] = useState(false);

  const location = useLocation();
  //console.log(location.state);
  const id = location.state.id;
  const task_ordering_group = location.state.task_ordering_group;
  const batch_order = location.state.batch_order;
  const [attention_checks_failed, setAttentionChecksFailed] = useState(
    location.state.attention_checks_failed
  );
  //console.log("Attention checks failed: " + attention_checks_failed);
  //console.log("id: " + id);

  let batch_order_pre = review_1;

  if (batch_order) {
    batch_order_pre = review_2;
  }

  const [reviews_file, setReviewsFile] = useState(batch_order_pre);

  //console.log(reviews_file);

  let init_id_order = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

  init_id_order.splice(4, 0, 10);

  //If the length of the reviews is changed, update this array
  const [id_order, setIdOrder] = useState(init_id_order);

  const [answers, setAnswers] = useState(new Map());

  const [confidence, setConfidence] = useState(new Map());

  const [confidenceClicked, setConfidenceClicked] = useState("");

  const [showConfidenceText, setShowConfidenceText] = useState(false);

  //actual id
  const [current_review_id, setCurrentReview] = useState(0);

  const [current_review_counter, setCurrentReviewCounter] = useState(0);

  const [attentionCheckClicked, setAttentionCheckClicked] = useState(false); //can adjust if needed
  const [attentionCheckConfidenceClicked, setAttentionCheckConfidenceClicked] =
    useState(false); //can adjust if needed
  const [attentionCheck, setAttentionCheck] = useState(""); //can adjust if needed
  const [attentionCheckConfidence, setAttentionCheckConfidence] = useState(""); //can adjust if needed
  const [attentionCheckValue, setAttentionCheckValue] = useState(""); //can adjust if needed
  const [attentionCheckConfidenceValue, setAttentionCheckConfidenceValue] =
    useState(""); //can adjust if needed

  function checkAttentionChecks1() {
    if (
      (!attentionCheckClicked ||
        attentionCheck === "wrong" ||
        attentionCheckConfidence === "wrong" ||
        !attentionCheckConfidenceClicked) &&
      attention_checks_failed >= 1
    ) {
      return (
        <a
          href="https://app.prolific.co/submissions/complete?cc=C11CKFDX"
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
        >
          Next
        </a>
      );
    } else if (
      !attentionCheckClicked ||
      attentionCheck === "wrong" ||
      attentionCheckConfidence === "wrong" ||
      !attentionCheckConfidenceClicked
    ) {
      return (
        <button
          onClick={clickedNextAttentionCheckWrong}
          className="mx-auto rounded-sm bg-blue-400 text-base text-gray-900 h-14 w-28 hover:opacity-80 font-semibold"
          value="next"
        >
          Next
        </button>
      );
    } else {
      return (
        <button
          onClick={clickedNextAttentionCheckRight}
          className="mx-auto rounded-sm bg-blue-400 text-base text-gray-900 h-14 w-28 hover:opacity-80 font-semibold"
          value="next"
        >
          Next
        </button>
      );
    }
  }

  function sendAttentionCheckInfo(atp) {
    var json_response = {};
    json_response.id = id;
    json_response.stage = "assignments_1";
    json_response.passed = atp;
    fetch(
      "https://thesis-crowdworker-app-backend-k5cvyplkba-ez.a.run.app/user-group/attention-checks/main",
      {
        mode: "no-cors",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(json_response),
      }
    );
  }

  const clickedNextAttentionCheckWrong = (e) => {
    sendAttentionCheckInfo(false);
    setAttentionChecksFailed(attention_checks_failed + 1);
    setCountDown(countDownValue);
    setClicked("");
    setCurrentReview(current_review_id + 1);
    if (current_review_id < review_html_strings.length) {
      setCurrentReview(current_review_id + 1);
    }
    setCurrentReviewCounter(current_review_counter + 2);
    // if (current_review_id === review_html_strings.length - 2) {
    //   setGoNextPage(true);
    // }
  };

  const clickedNextAttentionCheckRight = (e) => {
    sendAttentionCheckInfo(true);
    setCountDown(countDownValue);
    setClicked("");
    setCurrentReview(current_review_id + 1);
    if (current_review_id < review_html_strings.length) {
      setCurrentReview(current_review_id + 1);
    }
    setCurrentReviewCounter(current_review_counter + 2);
    // if (current_review_id === review_html_strings.length - 2) {
    //   setGoNextPage(true);
    // }
  };

  const countDownValue = 0;
  const countDownValue2 = 0;

  const [countDown, setCountDown] = useState(countDownValue);
  const [showTimerText, setShowTimerText] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
        //console.log("Countdown: " + countDown);
      } else {
        setShowTimerText(false);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);

  function displayTimerText() {
    if (showTimerText && phase2) {
      return (
        <p className="text-red-500 font-bold">
          We expect you to think at least 15 seconds about every answer.
        </p>
      );
    } else if (showTimerText) {
      return (
        <p className="text-red-500 font-bold">
          We expect you to think at least 25 seconds about every answer.
        </p>
      );
    } else {
      return;
    }
  }

  function displayConfidenceText() {
    if (showConfidenceText) {
      return (
        <p className="text-red-300 font-bold">
          Please indicate how confident you are of this answer
        </p>
      );
    } else {
      return;
    }
  }

  const [phase2, setPhase2] = useState(false);

  const [clicked, setClicked] = useState("");

  const [goNextPage, setGoNextPage] = useState(false);

  const [noneClickedButNextPressed, setNoneClickedButNextPressed] =
    useState(false);

  const checkClickedPositive = () => {
    if (clicked === "t") {
      return "bg-green-600 border-gray-600 border-2";
    } else {
      return "bg-green-300";
    }
  };

  function checkClickedNegative() {
    if (clicked === "d") {
      return "bg-red-500 border-gray-600 border-2";
    } else {
      return "bg-red-300";
    }
  }

  function checkConfidenceVU() {
    if (confidenceClicked === "-2") {
      return "bg-gray-400 border-gray-400";
    } else {
      return "bg-gray-200 border-gray-300";
    }
  }

  function checkConfidenceRU() {
    if (confidenceClicked === "-1") {
      return "bg-gray-400 border-gray-400";
    } else {
      return "bg-gray-200 border-gray-300";
    }
  }

  function checkConfidenceN() {
    if (confidenceClicked === "0") {
      return "bg-gray-400 border-gray-400";
    } else {
      return "bg-gray-200 border-gray-300";
    }
  }

  function checkConfidenceRC() {
    if (confidenceClicked === "1") {
      return "bg-gray-400 border-gray-400";
    } else {
      return "bg-gray-200 border-gray-300";
    }
  }

  function checkConfidenceVC() {
    if (confidenceClicked === "2") {
      return "bg-gray-400 border-gray-400";
    } else {
      return "bg-gray-200 border-gray-300";
    }
  }

  const checkClickedPositiveAT = () => {
    if (attentionCheckValue === "t") {
      return "bg-green-600 border-gray-600 border-2";
    } else {
      return "bg-green-300";
    }
  };

  function checkClickedNegativeAT() {
    if (attentionCheckValue === "d") {
      return "bg-red-500 border-gray-600 border-2";
    } else {
      return "bg-red-300";
    }
  }

  function checkConfidenceVUAT() {
    if (attentionCheckConfidenceValue === "-2") {
      return "bg-gray-400 border-gray-400";
    } else {
      return "bg-gray-200 border-gray-300";
    }
  }

  function checkConfidenceRUAT() {
    if (attentionCheckConfidenceValue === "-1") {
      return "bg-gray-400 border-gray-400";
    } else {
      return "bg-gray-200 border-gray-300";
    }
  }

  function checkConfidenceNAT() {
    if (attentionCheckConfidenceValue === "0") {
      return "bg-gray-400 border-gray-400";
    } else {
      return "bg-gray-200 border-gray-300";
    }
  }

  function checkConfidenceRCAT() {
    if (attentionCheckConfidenceValue === "1") {
      return "bg-gray-400 border-gray-400";
    } else {
      return "bg-gray-200 border-gray-300";
    }
  }

  function checkConfidenceVCAT() {
    if (attentionCheckConfidenceValue === "2") {
      return "bg-gray-400 border-gray-400";
    } else {
      return "bg-gray-200 border-gray-300";
    }
  }

  let reviews = reviews_file.reviews;

  let machine_flip_ids_init = [];

  let n1 = Math.floor(Math.random() * 10);
  let n2 = Math.floor(Math.random() * 10);
  while (n1 === n2) {
    n2 = Math.floor(Math.random() * 10);
  }
  machine_flip_ids_init.push(n1);
  machine_flip_ids_init.push(n2);

  //Machine predictions. These are ORDERED! So the index 0 gives the prediction for 0, 1 for 1, etc.
  const [machine_flip_ids, setPredictionFlipIDs] = useState(
    machine_flip_ids_init
  );

  // useEffect(() => {
  //   console.log(answers);
  //   console.log(confidence);
  // }, [confidence, answers]);

  useEffect(() => {
    //console.log("Machine flips: " + machine_flip_ids);
    //console.log("Current ID: " + id_order[current_review_id]);
    //console.log(
    //   "Current correct classification: " +
    //     reviews[id_order[current_review_id]].classification
    // );
  }, [current_review_id, id_order, machine_flip_ids, reviews]);

  let initWordMap = [];

  for (let i = 0; i < reviews.length; i++) {
    initWordMap.push(new Map());
    for (let j = 0; j < reviews[i].hwords.length; j++) {
      initWordMap[i].set(reviews[i].hwords[j], reviews[i].attributes[j]);
    }
  }

  const [wordMap, setWordMap] = useState(initWordMap);

  let review_html_strings = [];

  // Function to display the reviews
  let process_text = (tokens, show_attribution) => {
    let rgb = (x) => {
      if (x < 0) return "255,0,0";
      else if (x === 0) {
        return "255,255,255";
      } else {
        return "0,225,0";
      }
    };
    let alpha = (x) => {
      return Math.pow(Math.abs(x), 0.4);
    };

    let token_marks = "";

    for (let i = 0; i < tokens.length; i++) {
      if (
        wordMap[id_order[current_review_id]].get(tokens[i]) !== undefined &&
        show_attribution
      ) {
        token_marks +=
          '<mark style="background-color:rgba(' +
          rgb(wordMap[id_order[current_review_id]].get(tokens[i])) +
          "," +
          alpha(wordMap[id_order[current_review_id]].get(tokens[i])) +
          "); value=" +
          tokens[i] +
          '";>' +
          tokens[i] +
          "</mark> ";
      } else {
        token_marks +=
          '<mark style="background-color:rgba(' +
          rgb(0) +
          "," +
          0 +
          ')">' +
          tokens[i] +
          "</mark> ";
      }
    }
    return token_marks;
  };

  // Pre-process reviews to be displayed
  for (let i = 0; i < reviews.length; i++) {
    review_html_strings.push(process_text(reviews[id_order[i]].tokens, false));
    review_html_strings.push(process_text(reviews[id_order[i]].tokens, true));
  }

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  // When a user clicks on the "POSITIVE" or "NEGATIVE" button
  let onClick = (e) => {
    setNoneClickedButNextPressed(false);
    let phase_indicator;

    if (phase2) {
      phase_indicator = "b";
    } else {
      phase_indicator = "a";
    }

    // if (!answers.has(current_review_id)) {
    if (e.target.value === "t") {
      answers.set(id_order[current_review_id] + phase_indicator, 1);
      setClicked("t");
    } else {
      answers.set(id_order[current_review_id] + phase_indicator, 0);
      setClicked("d");
    }
    // }

    setAnswers(answers);

    // console.log(answers);
  };

  let onClickConfidence = (e) => {
    let phase_indicator;

    if (phase2) {
      phase_indicator = "b";
    } else {
      phase_indicator = "a";
    }

    confidence.set(
      id_order[current_review_id] + phase_indicator,
      e.target.value
    );

    setConfidenceClicked(e.target.value);
    setShowConfidenceText(false);
    setConfidence(confidence);

    // console.log(confidence);
  };

  const clickNext = (e) => {
    if (clicked !== "" && countDown === 0 && confidenceClicked !== "") {
      setCurrentReviewCounter(current_review_counter + 1);
      if (current_review_counter < review_html_strings.length) {
        let batch1, batch2;
        if (batch_order) {
          batch1 = 2;
          batch2 = 1;
        } else {
          batch1 = 1;
          batch2 = 2;
        }
        if (phase2) {
          let json_response = {};
          json_response.id = id;
          json_response.question_id = String(id_order[current_review_id] + "b");
          json_response.flips = machine_flip_ids;
          json_response.answer = clicked;
          json_response.batch1 = batch1;
          json_response.batch2 = batch2;
          json_response.confidence = confidenceClicked;
          json_response.question_order = id_order.toString();
          fetch(
            "https://thesis-crowdworker-app-backend-k5cvyplkba-ez.a.run.app/user-group/submit-answers-live/t1",
            {
              mode: "no-cors",
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(json_response),
            }
          );
          setPhase2(false);
          setCurrentReview(current_review_id + 1);
          setCountDown(countDownValue);
        } else {
          let json_response = {};
          json_response.id = id;
          json_response.question_id = String(id_order[current_review_id] + "a");
          json_response.flips = machine_flip_ids;
          json_response.answer = clicked;
          json_response.batch1 = batch1;
          json_response.batch2 = batch2;
          json_response.confidence = confidenceClicked;
          json_response.question_order = id_order.toString();
          fetch(
            "https://thesis-crowdworker-app-backend-k5cvyplkba-ez.a.run.app/user-group/submit-answers-live/t1",
            {
              mode: "no-cors",
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(json_response),
            }
          );
          setPhase2(true);
          setCountDown(countDownValue2);
        }
      }
      if (current_review_counter === review_html_strings.length - 2) {
        setGoNextPage(true);
      }
      setClicked("");
      setConfidenceClicked("");
      setShowConfidenceText(false);
    } else {
      if (clicked === "") {
        setNoneClickedButNextPressed(true);
      }
      if (countDown > 0) {
        setShowTimerText(true);
      }
      if (!confidenceClicked) {
        setShowConfidenceText(true);
      }
    }
  };

  const clickNextPage = (e) => {
    if (!allDataSent) {
      let batch1, batch2;
      if (batch_order) {
        batch1 = 2;
        batch2 = 1;
      } else {
        batch1 = 1;
        batch2 = 2;
      }

      let json_answers = JSON.stringify(Object.fromEntries(answers));
      let json_confidence = JSON.stringify(Object.fromEntries(confidence));
      let json_response = {};
      json_response.id = id;
      json_response.flips = machine_flip_ids;
      json_response.answers = json_answers;
      json_response.confidences = json_confidence;
      json_response.batch1 = batch1;
      json_response.batch2 = batch2;
      json_response.question_order = id_order.toString();

      let json_response2 = {};
      json_response2.id = id;
      json_response2.question_id = String(id_order[current_review_id] + "b");
      json_response2.flips = machine_flip_ids;
      json_response2.answer = clicked;
      json_response2.batch1 = batch1;
      json_response2.batch2 = batch2;
      json_response2.confidence = confidenceClicked;
      json_response2.question_order = id_order.toString();

      fetch(
        "https://thesis-crowdworker-app-backend-k5cvyplkba-ez.a.run.app/user-group/submit-answers-live/t1",
        {
          mode: "no-cors",
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(json_response2),
        }
      );

      fetch(
        "https://thesis-crowdworker-app-backend-k5cvyplkba-ez.a.run.app/submit-answers/t1",
        {
          mode: "no-cors",
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(json_response),
        }
      );
      setAllDataSent(true);
    } else {
      navigate("/post_task_questionnaire_tr/", {
        state: {
          attention_checks_failed: attention_checks_failed,
          id: id,
          task_ordering_group: task_ordering_group,
          batch_order: batch_order,
        },
      });
    }
  };

  function checkSentiment() {
    if (machine_flip_ids.includes(id_order[current_review_id])) {
      if (reviews[id_order[current_review_id]].classification === "d") {
        return '<p style="color:green">Genuine</p>';
      } else {
        return '<p style="color:red">Deceptive</p>';
      }
    } else {
      if (reviews[id_order[current_review_id]].classification === "t") {
        return '<p style="color:green">Genuine</p>';
      } else {
        return '<p style="color:red">Deceptive</p>';
      }
    }
  }

  function showSentiment() {
    if (phase2) {
      return (
        <p className="flex-row flex py-2 pl-1">
          <mark className="bg-white font-bold">AI advice:&nbsp;</mark>
          <div
            className=""
            dangerouslySetInnerHTML={{
              __html: checkSentiment(),
            }}
          />
        </p>
      );
    }
  }

  function noneClickedButNextPressedDisplayText() {
    if (noneClickedButNextPressed) {
      return (
        <p className="text-red-700 font-bold">
          You can not proceed without selecting your answer. Please do so before
          pressing 'Next'.
        </p>
      );
    }
  }

  function showNextButton() {
    if (
      goNextPage &&
      clicked !== "" &&
      confidenceClicked !== "" &&
      countDown === 0
    ) {
      return (
        <button
          className="mx-auto rounded-sm bg-blue-300 px-4 py-1 text-base text-gray-900 h-12 w-24 hover:opacity-80"
          onClick={clickNextPage}
        >
          Next
        </button>
      );
    } else {
      return (
        <button
          onClick={clickNext}
          className="mx-auto rounded-sm bg-blue-300 px-4 py-1 text-base text-gray-900 h-12 w-24 hover:opacity-80"
          value="next"
        >
          Next
        </button>
      );
    }
  }

  function showLine() {
    if (phase2) {
      return <hr className="pt-2 mt-3" />;
    } else {
      return;
    }
  }

  if (id_order[current_review_id] === 10) {
    return (
      <div className="mx-auto flex min-w-4xl max-w-4xl flex-col space-y-8 py-4">
        <button
          className="bg-orange-100 text-orange-900 active:bg-orange-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={() => setShowModal(true)}
        >
          Click here to view the guidelines for Deceptive Review Detection
        </button>
        {showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold">
                      Deceptive Review Detection Guidelines
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"></span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <p className="text-lg font-bold">
                      Here are some guidelines that can help you finding
                      deceptive reviews:
                    </p>
                    <li className="text-lg">
                      Deceptive reviews tend to focus on aspects that are
                      external to the hotel being reviewed, e.g., husband,
                      busines, vacation.
                    </li>
                    <li className="text-lg">
                      Deceptive reviews tend to contain more emotional terms;
                      positive deceptive reviews are generally more positive and
                      negative deceptive reviews are more negative than genuine
                      reviews.
                    </li>
                    <li className="text-lg">
                      Genuine reviews tend to include more sensorial and
                      concrete language, in particular, genuine reviews are more
                      specific about spatial configurations, e.g., small,
                      bathroom, on, location.
                    </li>
                    <li className="text-lg">
                      Deceptive reviews tend to contain more verbs, e.g., eat,
                      sleep, stay.
                    </li>
                    <li className="text-lg">
                      Deceptive reviews tend to contain more superlatives, e.g.,
                      cleanest, worst, best.
                    </li>
                    <li className="text-justify text-lg">
                      Deceptive reviews tend to contain more pre-determiners,
                      which are normally placed before an indefinite article +
                      adjective + noun, e.g., what a lovely day!
                    </li>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
        <h1 className="text-center text-xl">
          <span className="font-bold text-blue-400">
            Task: read the text below and follow its instructions carefully
          </span>
        </h1>
        <div className="text-justify border-4 border-stone-500 px-4 pb-2 pt-3 rounded-xl font-medium">
          <label className="flex ">
            Review's a little late, but... My husband &amp; I first stayed at
            the Amalfi in 8/06 totally based on reviews I read on this website.
            We were totally WOWED!! Loved the location, the room, the Aveda bath
            products, but most of all loved the evening reception &amp;
            breakfast on our floor. I returned to Chicago with 3 girlfriends in
            9/07 and booked us in a suite at the Amalfi. It was great - room was
            very spacious and my girlfriends were quite pleased! Will stay next
            time I'm in Chicago too!! P.S. They honor a government rate!!!! In
            order to confirm you have read this paragraph, please select Genuine
            and indicate that you are Very confident in this answer.
          </label>
        </div>
        <p className="font-bold text-lg text-center">
          Click the appropriate button to indicate your decision
        </p>
        <div className="flex">
          <button
            onClick={(e) => {
              setAttentionCheck("right");
              setAttentionCheckClicked(true);
              setAttentionCheckValue(e.target.value);
            }}
            className={
              "mx-auto rounded-sm px-4 py-1 text-base w-[49.5%] " +
              checkClickedPositiveAT()
            }
            value={"t"}
          >
            Genuine
          </button>
          <button
            onClick={(e) => {
              setAttentionCheck("wrong");
              setAttentionCheckClicked(true);
              setAttentionCheckValue(e.target.value);
            }}
            className={
              "mx-auto rounded-sm px-4 py-1 text-base w-[49.5%] " +
              checkClickedNegativeAT()
            }
            value={"d"}
          >
            Deceptive
          </button>
        </div>
        <p className="font-bold text-lg text-center">
          How confident are you in your answer?
        </p>
        <div className="flex flex-row relative space-x-1">
          <button
            className={
              "flex flex-col relative border-2 p-2 w-[20%] items-center " +
              checkConfidenceVUAT()
            }
            onClick={(e) => {
              setAttentionCheckConfidence("wrong");
              setAttentionCheckConfidenceClicked(true);
              setAttentionCheckConfidenceValue(e.target.value);
            }}
            value={-2}
          >
            Very unconfident
          </button>
          <button
            className={
              "flex flex-col relative border-2 p-2 w-[20%] items-center " +
              checkConfidenceRUAT()
            }
            onClick={(e) => {
              setAttentionCheckConfidence("wrong");
              setAttentionCheckConfidenceClicked(true);
              setAttentionCheckConfidenceValue(e.target.value);
            }}
            value={-1}
          >
            Rather unconfident
          </button>
          <button
            className={
              "flex flex-col relative border-2 p-2 w-[20%] items-center " +
              checkConfidenceNAT()
            }
            onClick={(e) => {
              setAttentionCheckConfidence("wrong");
              setAttentionCheckConfidenceClicked(true);
              setAttentionCheckConfidenceValue(e.target.value);
            }}
            value={0}
          >
            Neutral
          </button>
          <button
            className={
              "flex flex-col relative border-2 p-2 w-[20%] items-center " +
              checkConfidenceRCAT()
            }
            onClick={(e) => {
              setAttentionCheckConfidence("wrong");
              setAttentionCheckConfidenceClicked(true);
              setAttentionCheckConfidenceValue(e.target.value);
            }}
            value={1}
          >
            Rather confident
          </button>
          <button
            className={
              "flex flex-col relative border-2 p-2 w-[20%] items-center " +
              checkConfidenceVCAT()
            }
            onClick={(e) => {
              setAttentionCheckConfidence("right");
              setAttentionCheckConfidenceClicked(true);
              setAttentionCheckConfidenceValue(e.target.value);
            }}
            value={2}
          >
            Very confident
          </button>
        </div>
        <br></br>
        <div className="flex">{checkAttentionChecks1()}</div>
      </div>
    );
  } else if (allDataSent) {
    return (
      <div>
        <br />
        <br />
        <span className="font-bold text-green-500 text-2xl text-center items-center align-center">
          Good job, you have finished all the tasks! Click the{" "}
          <mark className="bg-white italic text-green-500">next</mark>-button to
          proceed to the next stage.
        </span>
        <br />
        <br />
        <br />
        <div className="flex relative content-right">{showNextButton()}</div>
      </div>
    );
  } else {
    return (
      <div className="mx-auto flex min-w-4xl max-w-4xl flex-col space-y-8 py-4">
        <button
          className="bg-orange-100 text-orange-900 active:bg-orange-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={() => setShowModal(true)}
        >
          Click here to view the guidelines for Deceptive Review Detection
        </button>
        {showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold">
                      Deceptive Review Detection Guidelines
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"></span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <p className="text-lg font-bold">
                      Here are some guidelines that can help you finding
                      deceptive reviews:
                    </p>
                    <li className="text-lg">
                      Deceptive reviews tend to focus on aspects that are
                      external to the hotel being reviewed, e.g., husband,
                      busines, vacation.
                    </li>
                    <li className="text-lg">
                      Deceptive reviews tend to contain more emotional terms;
                      positive deceptive reviews are generally more positive and
                      negative deceptive reviews are more negative than genuine
                      reviews.
                    </li>
                    <li className="text-lg">
                      Genuine reviews tend to include more sensorial and
                      concrete language, in particular, genuine reviews are more
                      specific about spatial configurations, e.g., small,
                      bathroom, on, location.
                    </li>
                    <li className="text-lg">
                      Deceptive reviews tend to contain more verbs, e.g., eat,
                      sleep, stay.
                    </li>
                    <li className="text-lg">
                      Deceptive reviews tend to contain more superlatives, e.g.,
                      cleanest, worst, best.
                    </li>
                    <li className="text-justify text-lg">
                      Deceptive reviews tend to contain more pre-determiners,
                      which are normally placed before an indefinite article +
                      adjective + noun, e.g., what a lovely day!
                    </li>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
        <h1 className="text-center text-xl">
          <span className="font-bold text-blue-400">
            Task: decide whether the following review is genuine or deceptive
          </span>
        </h1>
        <div className="text-justify border-4 border-stone-500 px-4 pb-2 pt-3 rounded-xl font-medium">
          <p>
            {
              <p
                dangerouslySetInnerHTML={{
                  __html: review_html_strings[current_review_counter],
                }}
              />
            }
          </p>
          {showLine()}
          {showSentiment()}
        </div>{" "}
        <p className="font-bold text-lg text-center">
          Click the appropriate button to indicate your decision
        </p>
        <div className="flex">
          <button
            onClick={onClick}
            className={
              "mx-auto rounded-sm px-4 py-1 text-base w-[49.5%] " +
              checkClickedPositive()
            }
            value={"t"}
          >
            Genuine
          </button>
          <button
            onClick={onClick}
            className={
              "mx-auto rounded-sm px-4 py-1 text-base w-[49.5%] " +
              checkClickedNegative()
            }
            value={"d"}
          >
            Deceptive
          </button>
        </div>
        <p className="font-bold text-lg text-center">
          How confident are you in your answer?
        </p>
        <div className="flex flex-row relative space-x-1">
          <button
            className={
              "flex flex-col relative border-2 p-2 w-[20%] items-center " +
              checkConfidenceVU()
            }
            onClick={onClickConfidence}
            value={-2}
          >
            Very unconfident
          </button>
          <button
            className={
              "flex flex-col relative border-2 p-2 w-[20%] items-center " +
              checkConfidenceRU()
            }
            onClick={onClickConfidence}
            value={-1}
          >
            Rather unconfident
          </button>
          <button
            className={
              "flex flex-col relative border-2 p-2 w-[20%] items-center " +
              checkConfidenceN()
            }
            onClick={onClickConfidence}
            value={0}
          >
            Neutral
          </button>
          <button
            className={
              "flex flex-col relative border-2 p-2 w-[20%] items-center " +
              checkConfidenceRC()
            }
            onClick={onClickConfidence}
            value={1}
          >
            Rather confident
          </button>
          <button
            className={
              "flex flex-col relative border-2 p-2 w-[20%] items-center " +
              checkConfidenceVC()
            }
            onClick={onClickConfidence}
            value={2}
          >
            Very confident
          </button>
        </div>
        <div className="flex relative content-right">{showNextButton()}</div>
        {noneClickedButNextPressedDisplayText()}
        {displayConfidenceText()}
        {displayTimerText()}
      </div>
    );
  }
}

export default Assignments;
