import "./Assignments_Instructions3.css";
import { Link, useParams } from "react-router-dom";
import tasks_tutorial from "./videos/pilot_study_tasks_tutorial.mp4";
import { useEffect, useState } from "react";

function Assignments_Instructions3() {
  const { id } = useParams();

  const countDownValue = 30;
  const [countDown, setCountDown] = useState(countDownValue);
  const [showTimerText, setShowTimerText] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
        // console.log("Countdown: " + countDown);
      } else {
        setShowTimerText(false);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);

  function displayTimerText() {
    if (showTimerText) {
      return (
        <p className="text-red-500 font-bold">
          Please read the instructions and watch the video before you continue.
        </p>
      );
    } else {
      return;
    }
  }

  function showNextButton() {
    if (countDown === 0) {
      return (
        <Link
          to={"/pre_task_questionnaire_c/" + id}
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900"
        >
          Next
        </Link>
      );
    } else {
      return (
        <button
          onClick={() => {
            setShowTimerText(true);
          }}
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900"
          value="next"
        >
          Next
        </button>
      );
    }
  }

  return (
    <div className="flex flex-col mx-auto max-w-6xl space-y-6 py-4 m-2">
      <h1 className="text-xl text-center">
        <span className="font-bold text-blue-400 text-4xl">
          Task instructions: Deception Detection
        </span>
      </h1>
      <p className="text-justify text-lg">
        In this stage, you will have to complete{" "}
        <mark className="bg-white font-bold">20 tasks</mark>. Given below are
        instructions that will explain what is expected at every task. A video
        has been included to show you what the process looks like for one
        instance of the task.
      </p>
      <br />
      <p className="text-justify text-lg">
        The task that you will be presented with is called{" "}
        <mark className="bg-white italic">Deception Detection</mark>. You will
        be shown a variety of hotel reviews. Your job is to decide which ones
        are <mark className="bg-white font-bold">deceptive </mark>
        and which ones are <mark className="bg-white font-bold">genuine</mark>.
        A <mark className="bg-white font-bold">deceptive </mark> hotel review is
        one that has been made up. The person writing such a review has never
        visited the hotel and therefore does not write about actual experiences
        at the hotel. A <mark className="bg-white font-bold">genuine</mark> is
        the exact opposite. The writers have visited the hotel and write about
        their experiences there.
      </p>
      <div>
        <p className="text-lg font-bold">
          Here are some guidelines that can help you finding deceptive reviews:
        </p>
        <li className="text-lg">
          Deceptive reviews tend to focus on aspects that are external to the
          hotel being reviewed, e.g., husband, busines, vacation.
        </li>
        <li className="text-lg">
          Deceptive reviews tend to contain more emotional terms; positive
          deceptive reviews are generally more positive and negative deceptive
          reviews are more negative than genuine reviews.
        </li>
        <li className="text-lg">
          Genuine reviews tend to include more sensorial and concrete language,
          in particular, genuine reviews are more specific about spatial
          configurations, e.g., small, bathroom, on, location.
        </li>
        <li className="text-lg">
          Deceptive reviews tend to contain more verbs, e.g., eat, sleep, stay.
        </li>
        <li className="text-lg">
          Deceptive reviews tend to contain more superlatives, e.g., cleanest,
          worst, best.
        </li>
        <li className="text-justify text-lg">
          Deceptive reviews tend to contain more pre-determiners, which are
          normally placed before an indefinite article + adjective + noun, e.g.,
          what a lovely day!
        </li>
      </div>
      <br />
      <p className="text-justify text-lg font-bold">
        Steps for completing the tasks:
      </p>
      <p className="text-justify text-lg">
        <mark className="bg-white font-bold">Step 1 </mark> You are presented
        with a review. Read it carefully and decide whether it is deceptive or
        genuine
      </p>
      <p className="text-justify text-lg">
        <mark className="bg-white font-bold">Step 2 </mark> When you have
        decided, lock in your choice using the buttons and click on the{" "}
        <mark className="bg-white italic">next</mark>-button.
      </p>
      <br />
      <p className="text-justify text-lg font-bold">
        Below, you will find an instruction video showing the process described
        above.
      </p>
      <video controls>
        <source src={tasks_tutorial} type="video/mp4" />
      </video>
      {showNextButton()}
      {displayTimerText()}
    </div>
  );
}

export default Assignments_Instructions3;
