import "./Assignments_c.css";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import reviews_file from "./reviews_c.json";

function Assignments_c() {
  const { id } = useParams();
  const [attentionCheckClicked, setAttentionCheckClicked] = useState(false); //can adjust if needed
  const [attentionCheck2Clicked, setAttentionCheck2Clicked] = useState(false); //can adjust if needed

  const [attentionCheck, setAttentionCheck] = useState(""); //can adjust if needed
  const [attentionCheck2, setAttentionCheck2] = useState(""); //can adjust if needed

  // useEffect(() => {
  //   console.log("Attention Check 1: " + attentionCheck);
  //   console.log("Attention Check 2: " + attentionCheck2);
  //   console.log(attentionCheck === "right" && attentionCheck2 === "right");
  // }, [attentionCheck, attentionCheck2]);

  function checkAttentionChecks1() {
    if (attentionCheckClicked) {
      if (attentionCheck === "wrong" && attentionCheck2 === "wrong") {
        return (
          <a
            href="https://app.prolific.co/submissions/complete?cc=C66DHLZ0"
            className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
          >
            Next
          </a>
        );
      } else {
        return showNextButtonAttentionCheck();
      }
    } else {
      return (
        <a
          href="https://app.prolific.co/submissions/complete?cc=C66DHLZ0"
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
        >
          Next
        </a>
      );
    }
  }

  function checkAttentionChecks2() {
    if (attentionCheck2Clicked) {
      if (attentionCheck === "wrong" && attentionCheck2 === "wrong") {
        return (
          <a
            href="https://app.prolific.co/submissions/complete?cc=C66DHLZ0"
            className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
          >
            Next
          </a>
        );
      } else {
        return showNextButtonAttentionCheck();
      }
    } else {
      return (
        <a
          href="https://app.prolific.co/submissions/complete?cc=C66DHLZ0"
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
        >
          Next
        </a>
      );
    }
  }

  function showNextButtonAttentionCheck() {
    return (
      <button
        onClick={clickedNextAttentionCheck}
        className="mx-auto rounded-sm bg-blue-400 text-base text-gray-900 h-14 w-28 hover:opacity-80 font-semibold"
        value="next"
      >
        Next
      </button>
    );
  }

  const countDownValue = 30;
  const [countDown, setCountDown] = useState(countDownValue);
  const [showTimerText, setShowTimerText] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
        // console.log("Countdown: " + countDown);
      } else {
        setShowTimerText(false);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);

  function displayTimerText() {
    if (showTimerText) {
      return (
        <p className="text-red-500 font-bold">
          We expect you to think at least 30 seconds about every answer.
        </p>
      );
    } else {
      return;
    }
  }

  let init_id_order = shuffle([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
  ]);

  init_id_order.splice(6, 0, 20);
  init_id_order.splice(13, 0, 21);

  //DEPENDENT ON N OF REVIEWS
  const [id_order, setIdOrder] = useState(init_id_order);
  // console.log(id_order);

  const [answers, setAnswers] = useState(new Map());

  //actual id
  const [current_review_id, setCurrentReview] = useState(0);

  const [clicked, setClicked] = useState("");

  const [goNextPage, setGoNextPage] = useState(false);

  const [noneClickedButNextPressed, setNoneClickedButNextPressed] =
    useState(false);

  const checkClickedPositive = () => {
    if (clicked === "t") {
      return "bg-green-600 border-gray-600 border-2";
    } else {
      return "bg-green-300";
    }
  };

  function checkClickedNegative() {
    if (clicked === "d") {
      return "bg-red-500 border-gray-600 border-2";
    } else {
      return "bg-red-300";
    }
  }

  let reviews = reviews_file.reviews;

  // let tempAnswersForPilot = [];
  // for (let i = 0; i < reviews.length; i++) {
  //   if (reviews[i].classification === "t") {
  //     tempAnswersForPilot.push(1);
  //   } else {
  //     tempAnswersForPilot.push(0);
  //   }
  // }
  // console.log(tempAnswersForPilot);

  // useEffect(() => {
  //   console.log("Current ID: " + id_order[current_review_id]);
  //   console.log(
  //     "Current correct classification: " +
  //       reviews[id_order[current_review_id]].classification
  //   );
  // }, [current_review_id, id_order, reviews]);

  let initWordMap = [];

  for (let i = 0; i < reviews.length; i++) {
    initWordMap.push(new Map());
    for (let j = 0; j < reviews[i].hwords.length; j++) {
      initWordMap[i].set(reviews[i].hwords[j], reviews[i].attributes[j]);
    }
  }

  const [wordMap, setWordMap] = useState(initWordMap);

  let review_html_strings = [];

  // Function to display the reviews
  let process_text = (tokens, show_attribution) => {
    let rgb = (x) => {
      if (x < 0) return "255,0,0";
      else if (x === 0) {
        return "255,255,255";
      } else {
        return "0,225,0";
      }
    };
    let alpha = (x) => {
      return Math.pow(Math.abs(x), 0.4);
    };

    let token_marks = "";

    for (let i = 0; i < tokens.length; i++) {
      if (
        wordMap[id_order[current_review_id]].get(tokens[i]) !== undefined &&
        show_attribution
      ) {
        token_marks +=
          '<mark style="background-color:rgba(' +
          rgb(wordMap[id_order[current_review_id]].get(tokens[i])) +
          "," +
          alpha(wordMap[id_order[current_review_id]].get(tokens[i])) +
          "); value=" +
          tokens[i] +
          '";>' +
          tokens[i] +
          "</mark> ";
      } else {
        token_marks +=
          '<mark style="background-color:rgba(' +
          rgb(0) +
          "," +
          0 +
          ')">' +
          tokens[i] +
          "</mark> ";
      }
    }
    return token_marks;
  };

  // Pre-process reviews to be displayed
  for (let i = 0; i < reviews.length; i++) {
    review_html_strings.push(process_text(reviews[id_order[i]].tokens, false));
  }

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  // When a user clicks on the "POSITIVE" or "NEGATIVE" button
  let onClick = (e) => {
    setNoneClickedButNextPressed(false);

    // if (!answers.has(current_review_id)) {
    if (e.target.value === "t") {
      answers.set(id_order[current_review_id], 1);
      setClicked("t");
    } else {
      answers.set(id_order[current_review_id], 0);
      setClicked("d");
    }
    // }

    setAnswers(answers);

    // console.log(answers);
  };

  const clickedNextAttentionCheck = (e) => {
    setCountDown(countDownValue);
    setClicked("");
    setCurrentReview(current_review_id + 1);
    if (current_review_id < review_html_strings.length) {
      setCurrentReview(current_review_id + 1);
    }
    if (current_review_id === review_html_strings.length - 2) {
      setGoNextPage(true);
    }
  };

  const clickNext = (e) => {
    if (countDown === 0) {
      if (clicked !== "") {
        setClicked("");
        setCurrentReview(current_review_id + 1);
        if (current_review_id < review_html_strings.length) {
          setCurrentReview(current_review_id + 1);
        }
        if (current_review_id === review_html_strings.length - 2) {
          setGoNextPage(true);
        }
        setCountDown(countDownValue);
      } else {
        setNoneClickedButNextPressed(true);
      }
    } else {
      if (clicked === "") {
        setNoneClickedButNextPressed(true);
      }
      setShowTimerText(true);
    }
  };

  const clickNextPage = (e) => {
    if (countDown === 0) {
      let json_answers = JSON.stringify(Object.fromEntries(answers));
      var json_response = {};
      json_response.id = id;
      json_response.answers = json_answers;
      // console.log(json_response);
      fetch(
        "https://thesis-crowdworker-app-backend-k5cvyplkba-ez.a.run.app/submit-answers/c",
        {
          mode: "no-cors",
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(json_response),
        }
      );
    } else {
      if (clicked === "") {
        setNoneClickedButNextPressed(true);
      }
      setShowTimerText(true);
    }
  };

  function noneClickedButNextPressedDisplayText() {
    if (noneClickedButNextPressed) {
      return (
        <p className="text-red-700 font-bold">
          You can not proceed without selecting your answer. Please do so before
          pressing 'Next'.
        </p>
      );
    }
  }

  function showNextButton() {
    if (goNextPage && clicked !== "") {
      return (
        <Link
          to={"/end_of_control/" + id}
          onClick={clickNextPage}
          className="mx-auto rounded-sm bg-blue-300 px-4 py-1 pt-3 text-base text-gray-900 h-12 w-24 text-center hover:opacity-80"
        >
          Next
        </Link>
      );
    } else {
      return (
        <button
          onClick={clickNext}
          className="mx-auto rounded-sm bg-blue-300 px-4 py-1 text-base text-gray-900 h-12 w-24 hover:opacity-80"
          value="next"
        >
          Next
        </button>
      );
    }
  }

  if (id_order[current_review_id] === 20) {
    return (
      <div>
        <label className="text-lg">
          What is the difference between deceptive reviews and genuine reviews?
          Failing to answer this question will lead to removal of the study.
        </label>
        <br />
        <br />

        <div className="flex flex-col relative text-lg">
          <p>
            <input
              type="radio"
              className=""
              name="at"
              value="a"
              onChange={(e) => {
                setAttentionCheck("right");
                setAttentionCheckClicked(true);
              }}
              required
            />
            &nbsp;Deceptive reviews are made up, genuine reviews are not.
          </p>
          <p>
            <input
              type="radio"
              className=""
              name="at"
              value="b"
              onChange={(e) => {
                setAttentionCheck("wrong");
                setAttentionCheckClicked(true);
              }}
            />
            &nbsp;There is no difference.
          </p>
        </div>
        <br></br>
        <div className="flex">{checkAttentionChecks1()}</div>
      </div>
    );
  } else if (id_order[current_review_id] === 21) {
    return (
      <div>
        <label className="text-lg">
          Is the statement 'the{" "}
          <mark className="bg-white italian">deceptive</mark>-button is green.'
          true or false? Failing to answer this question will lead to removal of
          the study.
        </label>
        <br />
        <br />
        <div className="flex flex-col relative text-lg">
          <p>
            <input
              type="radio"
              className=""
              name="q1"
              value="a"
              onChange={(e) => {
                setAttentionCheck2("wrong");
                setAttentionCheck2Clicked(true);
              }}
              required
            />
            &nbsp;True
          </p>
          <p>
            <input
              type="radio"
              className=""
              name="q1"
              value="b"
              onChange={(e) => {
                setAttentionCheck2("right");
                setAttentionCheck2Clicked(true);
              }}
            />
            &nbsp;False
          </p>
        </div>
        <br />
        <div className="flex">{checkAttentionChecks2()}</div>
      </div>
    );
  } else {
    return (
      <div className="mx-auto flex min-w-4xl max-w-4xl flex-col space-y-8 py-4">
        <h1 className="text-center text-xl">
          <span className="font-bold text-blue-400">
            Task: decide whether the following review is genuine or deceptive
          </span>
        </h1>
        <p className="text-justify">
          {
            <p
              dangerouslySetInnerHTML={{
                __html: review_html_strings[current_review_id],
              }}
            />
          }
        </p>
        <div className="flex">
          <button
            onClick={onClick}
            className={
              "mx-auto rounded-sm px-4 py-1 text-base w-[49.5%] " +
              checkClickedPositive()
            }
            value={"t"}
          >
            Genuine
          </button>
          <button
            onClick={onClick}
            className={
              "mx-auto rounded-sm px-4 py-1 text-base w-[49.5%] " +
              checkClickedNegative()
            }
            value={"d"}
          >
            Deceptive
          </button>
        </div>
        <div className="flex relative content-right">{showNextButton()}</div>
        {noneClickedButNextPressedDisplayText()}
        {displayTimerText()}
      </div>
    );
  }
}

export default Assignments_c;
