import "./Assignments_Instructions1.css";
import { Link, useLocation, useParams } from "react-router-dom";
import tasks_tutorial from "./videos/tasks_tutorial.mp4";
import { useEffect, useState } from "react";

function Assignments_Instructions1() {
  const location = useLocation();
  const id = location.state.id;
  const task_ordering_group = location.state.task_ordering_group;
  const batch_order = location.state.batch_order;
  const [attention_checks_failed, setAttentionChecksFailed] = useState(
    location.state.attention_checks_failed
  );

  const countDownValue = 0;
  const [countDown, setCountDown] = useState(countDownValue);
  const [showTimerText, setShowTimerText] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
      } else {
        setShowTimerText(false);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);

  function displayTimerText() {
    if (showTimerText) {
      return (
        <p className="text-red-500 font-bold">
          Please read the instructions before you continue.
        </p>
      );
    } else {
      return;
    }
  }

  function showNextButton() {
    if (countDown === 0) {
      return (
        <Link
          to={"/assignments/"}
          state={{
            attention_checks_failed: attention_checks_failed,
            id: id,
            task_ordering_group: task_ordering_group,
            batch_order: batch_order,
          }}
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900"
        >
          Next
        </Link>
      );
    } else {
      return (
        <button
          onClick={() => {
            setShowTimerText(true);
          }}
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900"
          value="next"
        >
          Next
        </button>
      );
    }
  }

  return (
    <div className="flex flex-col mx-auto max-w-6xl space-y-6 py-4 m-2">
      <h1 className="text-xl text-center">
        <span className="font-bold text-blue-400 text-4xl">
          Task Instructions
        </span>
      </h1>
      <p className="text-justify text-lg">
        Well done! You have passed all qualification tests and now understand
        Deceptive Review Detection.
      </p>
      <p className="text-justify text-lg">
        In the first stage, you will be asked to answer 10 instances of this
        task. Feel free to check up on the guidelines by clicking the button
        that we provide you with.
      </p>
      <p className="text-justify text-lg font-bold">
        In order to successfully complete the tasks, you will have to follow
        some steps, which we have listed below:
      </p>
      <p className="text-justify text-lg">
        <mark className="bg-white font-bold">Step 1 </mark> You are presented
        with a review. Read it carefully and decide whether it is deceptive or
        genuine. Also indicate your confidence in the decision.
      </p>
      <p className="text-justify text-lg">
        <mark className="bg-white font-bold">Step 2 </mark> When you have
        decided, lock your choice in by using the buttons and click on the{" "}
        <mark className="bg-white italic">next</mark>-button.
      </p>
      <p className="text-justify text-lg">
        <mark className="bg-white font-bold">Step 3 </mark> You are presented
        with the same review, along with supporting highlights and advice from
        an AI system. By considering the highlights and AI advice, decide
        whether the review is genuine or deceptive. Once again indicate your
        confidence in your final decision.
      </p>
      <p className="text-justify text-lg">
        <mark className="bg-white font-bold">Step 4 </mark> When you have
        decided, lock your choice in by using the buttons and click on the{" "}
        <mark className="bg-white italic">next</mark>-button to move forward to
        the next page.
      </p>
      <p className="text-justify text-lg font-semibold text-red-500">
        If you get stuck at any of these steps, you can use the guidelines (by
        clicking on the button at the top of the interface) to help you figure
        out the right answer.
      </p>
      <br />
      <p className="text-justify text-lg font-bold">
        Below, you will find an instructional video demonstrating these steps.
      </p>

      <video controls>
        <source src={tasks_tutorial} type="video/mp4" />
      </video>
      {showNextButton()}
      {displayTimerText()}
    </div>
  );
}

export default Assignments_Instructions1;
