import { Link, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

function Assignments_Pre_Check_Instructions() {
  const location = useLocation();
  const id = location.state.id;
  const task_ordering_group = location.state.task_ordering_group;
  const batch_order = location.state.batch_order;
  const [attention_checks_failed, setAttentionChecksFailed] = useState(
    location.state.attention_checks_failed
  );

  const countDownValue = 0;
  const [countDown, setCountDown] = useState(countDownValue);
  const [showTimerText, setShowTimerText] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
      } else {
        setShowTimerText(false);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);

  function displayTimerText() {
    if (showTimerText) {
      return (
        <p className="text-red-500 font-bold">
          Please read the task description carefully before you continue.
        </p>
      );
    } else {
      return;
    }
  }

  function showNextButton() {
    if (countDown === 0) {
      return (
        <Link
          to={"/pre_task_questionnaire_1/"}
          state={{
            attention_checks_failed: attention_checks_failed,
            id: id,
            task_ordering_group: task_ordering_group,
            batch_order: batch_order,
          }}
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900"
        >
          Next
        </Link>
      );
    } else {
      return (
        <button
          onClick={() => {
            setShowTimerText(true);
          }}
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900"
          value="next"
        >
          Next
        </button>
      );
    }
  }

  return (
    <div className="flex flex-col mx-auto max-w-6xl space-y-6 py-4 m-2">
      <h1 className="text-xl text-center">
        <span className="font-bold text-blue-400 text-4xl">
          Task Introduction: Deceptive Review Detection
        </span>
      </h1>
      <p className="text-justify text-lg outline outline-orange-400">
        This page will introduce you to the task you will be completing in this
        study. Read all instructions carefully, as they will be tested with some
        qualification tests. If you don't pass these, you will be removed from
        the study.
      </p>
      <br />
      <p className="text-justify text-lg">
        The task that you will be presented with is called{" "}
        <mark className="bg-white italic">Deceptive Review Detection</mark>. You
        will be shown a variety of hotel reviews. Your job is to decide which
        ones are <mark className="bg-white font-bold">deceptive </mark>
        and which ones are <mark className="bg-white font-bold">
          genuine
        </mark>.{" "}
        <mark className="bg-red-100">
          A <mark className="bg-red-100 font-bold">deceptive </mark> hotel
          review is one that has been made up. The person writing such a review
          has never visited the hotel and therefore does not write about actual
          experiences at the hotel
        </mark>
        .{" "}
        <mark className="bg-green-100">
          A <mark className="font-bold bg-green-100">genuine</mark> review is
          the exact opposite. The writers have visited the hotel and write about
          their experiences there.
        </mark>
      </p>
      <p className="text-justify text-lg">
        Each review will be shown to you two times. The first time, you will
        only be shown the review in plain text. The second time, you will be
        shown that same review along with advice from an AI system. The advice
        from the AI system is explained with highlights in the review that
        support it. Highlights occur on single words and indicate how the AI
        system assesses the contribution of the word to a review that is more{" "}
        <mark className="bg-green-300">genuine</mark> or{" "}
        <mark className="bg-red-300">deceptive</mark>, depending on the color.
        Deeper colors are more indicative than lighter ones.
      </p>
      <div>
        <p className="text-xl font-bold">
          Here are some <mark className="bg-white underline">guidelines</mark>{" "}
          that can help you finding deceptive reviews:
        </p>
        <li className="text-lg">
          Deceptive reviews tend to focus on aspects that are external to the
          hotel being reviewed, e.g., husband, busines, vacation.
        </li>
        <li className="text-lg">
          Deceptive reviews tend to contain more emotional terms; positive
          deceptive reviews are generally more positive and negative deceptive
          reviews are more negative than genuine reviews.
        </li>
        <li className="text-lg">
          Genuine reviews tend to include more sensorial and concrete language,
          in particular, genuine reviews are more specific about spatial
          configurations, e.g., small, bathroom, on, location.
        </li>
        <li className="text-lg">
          Deceptive reviews tend to contain more verbs, e.g., eat, sleep, stay.
        </li>
        <li className="text-lg">
          Deceptive reviews tend to contain more superlatives, e.g., cleanest,
          worst, best.
        </li>
        <li className="text-justify text-lg">
          Deceptive reviews tend to contain more pre-determiners, which are
          normally placed before an indefinite article + adjective + noun, e.g.,
          what a lovely day!
        </li>
      </div>
      <br />
      {showNextButton()}
      {displayTimerText()}
    </div>
  );
}

export default Assignments_Pre_Check_Instructions;
