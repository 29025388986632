import "./Training_Phase_Introduction.css";
import { Link, useLocation, useParams } from "react-router-dom";

import { useEffect, useState } from "react";

function Training_Phase_Introduction() {
  const location = useLocation();
  const id = location.state.id;
  const task_ordering_group = location.state.task_ordering_group;
  const batch_order = location.state.batch_order;
  const [attention_checks_failed, setAttentionChecksFailed] = useState(
    location.state.attention_checks_failed
  );

  const countDownValue = 15;
  const [countDown, setCountDown] = useState(countDownValue);
  const [showTimerText, setShowTimerText] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
      } else {
        setShowTimerText(false);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);

  function displayTimerText() {
    if (showTimerText) {
      return (
        <p className="text-red-500 font-bold">
          Please read the instructions carefully before you continue.
        </p>
      );
    } else {
      return;
    }
  }

  function showNextButton() {
    if (countDown === 0) {
      return (
        <Link
          to={"/training_video/"}
          state={{
            attention_checks_failed: attention_checks_failed,
            id: id,
            task_ordering_group: task_ordering_group,
            batch_order: batch_order,
          }}
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900"
        >
          Next
        </Link>
      );
    } else {
      return (
        <button
          onClick={() => {
            setShowTimerText(true);
          }}
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900"
          value="next"
        >
          Next
        </button>
      );
    }
  }

  return (
    <div className="flex flex-col mx-auto max-w-6xl space-y-6 py-4 m-2">
      <h1 className="text-xl text-center">
        <span className="font-bold text-blue-400 text-4xl">
          Training: Introduction
        </span>
      </h1>
      <p className="text-justify text-lg">
        Well done, you finished the first stage! Are you good at identifying
        when AI advice is correct? Let's test it! In the next stage, you will be
        presented with a training phase consisting of a 3-minute long video and
        debugging exercises. Be sure to stay focused throughout the process!
      </p>
      <p className="text-justify text-lg">
        You will first be presented with an instructional video, which will tell
        you more about AI. It will focus on how AI works, its risks and
        limitations, and why it is important to never over-rely or under-rely on
        it.
      </p>
      <p className="text-justify text-lg">
        After the video, you will be asked to debug the AI system. In these
        tasks, the AI system will not always provide the correct advice and the
        explanations might contain some errors. Please try your best to find and
        correct the explanations.
      </p>
      <p className="text-justify text-lg font-bold">
        Be sure to pay attention when watching the video and doing the
        exercises. It will help you obtain additional rewards in the next stage!
      </p>
      <br />
      {showNextButton()}
      {displayTimerText()}
    </div>
  );
}

export default Training_Phase_Introduction;
