import { useState } from "react";
import { Link, useParams } from "react-router-dom";

import "./Questionnaire3.css";

function Questionnaire3(props) {
  const [answer1, setAnswer1] = useState(""); //can adjust if needed
  const [answer2, setAnswer2] = useState("");
  const [answer3, setAnswer3] = useState("");
  const [answer4, setAnswer4] = useState("");
  const [answer5, setAnswer5] = useState("");
  const [answer6, setAnswer6] = useState("");
  const [answer7, setAnswer7] = useState("");
  const [answer8, setAnswer8] = useState("");
  const [answer9, setAnswer9] = useState("");
  const [answer10, setAnswer10] = useState("");
  const [answer11, setAnswer11] = useState("");
  const [answer12, setAnswer12] = useState("");

  const { id } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();
    const answers = { answer1, answer2, answer3 };
    console.log(answers);
    fetch("https://thesis-crowdworker-app-backend-k5cvyplkba-ez.a.run.app/test", {
      mode: "no-cors",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(answers),
    });
  };

  // Don't use Link, use button and react-dom redirect, or make sure Link only works if everything has been filled in
  return (
    <div className="m-2">
      <form
        onSubmit={handleSubmit}
        className="relative flex flex-col space-y-4"
      >
        <p className="text-2xl text-center text-blue-400 font-bold">
          Please answer the following questions truthfully.
        </p>
        <br></br>
        <label className="text-lg">
          <mark className="bg-white font-semibold">Question 1</mark> What
          definition fits the term 'decision support system' best?
        </label>
        <div className="flex flex-col relative">
          <p>
            <input
              type="radio"
              className=""
              name="q1"
              value="a"
              onChange={(e) => {
                setAnswer1(e.target.value);
              }}
              required
            />
            &nbsp;A computer system that proposes questions in need of a
            decision to its users&nbsp;
          </p>
          <p>
            <input
              type="radio"
              className=""
              name="q1"
              value="b"
              onChange={(e) => {
                setAnswer1(e.target.value);
              }}
            />
            &nbsp;An information system that supports decision making
            activities&nbsp;
          </p>

          <p>
            <input
              type="radio"
              className=""
              name="q1"
              value="c"
              onChange={(e) => {
                setAnswer1(e.target.value);
              }}
            />
            &nbsp;A management tool that proposes answers to decisions to make
            an organization more profitable&nbsp;
          </p>

          <p>
            <input
              type="radio"
              className=""
              name="q1"
              value="d"
              onChange={(e) => {
                setAnswer1(e.target.value);
              }}
            />
            &nbsp;A program that outputs the correct decision to make when faced
            with a question&nbsp;
          </p>
        </div>
        <label className="text-lg">
          <mark className="bg-white font-semibold">Question 2</mark> What are
          the most fundamental components of a decision support system
          architecture?
        </label>
        <div className="flex flex-col relative">
          <p>
            <input
              type="radio"
              className=""
              name="q2"
              value="a"
              onChange={(e) => {
                setAnswer2(e.target.value);
              }}
              required
            />
            &nbsp;A set of conditions and a powerful computer&nbsp;
          </p>
          <p>
            <input
              type="radio"
              className=""
              name="q2"
              value="b"
              onChange={(e) => {
                setAnswer2(e.target.value);
              }}
            />
            &nbsp;A database, computer, user interface, users&nbsp;
          </p>

          <p>
            <input
              type="radio"
              className=""
              name="q2"
              value="c"
              onChange={(e) => {
                setAnswer2(e.target.value);
              }}
            />
            &nbsp;A knowledge base, decision making framework, user
            interface&nbsp;
          </p>

          <p>
            <input
              type="radio"
              className=""
              name="q2"
              value="d"
              onChange={(e) => {
                setAnswer2(e.target.value);
              }}
            />
            &nbsp;Training data, a set of conditions, a computer, user
            interface&nbsp;
          </p>
        </div>
        <label className="text-lg">
          <mark className="bg-white font-semibold">Question 3</mark> [Assumes a
          positive/negative review labeling task with a simple model] Do swear
          words always make a review more negative from the perspective of the
          system?
        </label>
        <div className="flex flex-col relative">
          <p>
            <input
              type="radio"
              className=""
              name="q3"
              value="a"
              onChange={(e) => {
                setAnswer3(e.target.value);
              }}
              required
            />
            &nbsp;Always&nbsp;
          </p>
          <p>
            <input
              type="radio"
              className=""
              name="q3"
              value="b"
              onChange={(e) => {
                setAnswer3(e.target.value);
              }}
            />
            &nbsp;Almost always&nbsp;
          </p>

          <p>
            <input
              type="radio"
              className=""
              name="q3"
              value="c"
              onChange={(e) => {
                setAnswer3(e.target.value);
              }}
            />
            &nbsp;Almost never&nbsp;
          </p>

          <p>
            <input
              type="radio"
              className=""
              name="q3"
              value="d"
              onChange={(e) => {
                setAnswer3(e.target.value);
              }}
            />
            &nbsp;Never&nbsp;
          </p>
        </div>
        <label className="text-lg">
          <mark className="bg-white font-semibold">Question 4</mark> [Assumes a
          positive/negative review labeling task with a simple model] How does
          the system decide if a review is positive?
        </label>
        <div className="flex flex-col relative">
          <p>
            <input
              type="radio"
              className=""
              name="q4"
              value="a"
              onChange={(e) => {
                setAnswer4(e.target.value);
              }}
              required
            />
            &nbsp;It rates the words used and decides based on the total score
            of a review&nbsp;
          </p>
          <p>
            <input
              type="radio"
              className=""
              name="q4"
              value="b"
              onChange={(e) => {
                setAnswer4(e.target.value);
              }}
            />
            &nbsp;It thinks about the answer, just like a human would do&nbsp;
          </p>

          <p>
            <input
              type="radio"
              className=""
              name="q4"
              value="c"
              onChange={(e) => {
                setAnswer4(e.target.value);
              }}
            />
            &nbsp;It compares it to other reviews that are very similar and
            assigns it the same label&nbsp;
          </p>

          <p>
            <input
              type="radio"
              className=""
              name="q4"
              value="d"
              onChange={(e) => {
                setAnswer4(e.target.value);
              }}
            />
            &nbsp;It picks a label at random&nbsp;
          </p>
        </div>
        <label className="text-lg">
          <mark className="bg-white font-semibold">Question 5</mark> Indicate
          the extent to which you agree with the statement "I am aware of the
          risks and limitations of decision support systems"
        </label>
        <div className="flex flex-col relative">
          <p>
            <input
              type="radio"
              className=""
              name="q5"
              value="a"
              onChange={(e) => {
                setAnswer5(e.target.value);
              }}
              required
            />
            &nbsp;Strongly disagree&nbsp;
          </p>
          <p>
            <input
              type="radio"
              className=""
              name="q5"
              value="b"
              onChange={(e) => {
                setAnswer5(e.target.value);
              }}
            />
            &nbsp;Disagree&nbsp;
          </p>

          <p>
            <input
              type="radio"
              className=""
              name="q5"
              value="c"
              onChange={(e) => {
                setAnswer5(e.target.value);
              }}
            />
            &nbsp;Neutral&nbsp;
          </p>

          <p>
            <input
              type="radio"
              className=""
              name="q5"
              value="d"
              onChange={(e) => {
                setAnswer5(e.target.value);
              }}
            />
            &nbsp;Agree&nbsp;
          </p>
          <p>
            <input
              type="radio"
              className=""
              name="q5"
              value="e"
              onChange={(e) => {
                setAnswer5(e.target.value);
              }}
            />
            &nbsp;Strongly agree&nbsp;
          </p>
        </div>
        <label className="text-lg">
          <mark className="bg-white font-semibold">Question 6</mark> Indicate
          the extent to which you agree with the statement "When working with a
          computer system, I always know how it will behave"
        </label>
        <div className="flex flex-col relative">
          <p>
            <input
              type="radio"
              className=""
              name="q6"
              value="a"
              onChange={(e) => {
                setAnswer6(e.target.value);
              }}
              required
            />
            &nbsp;Strongly disagree&nbsp;
          </p>
          <p>
            <input
              type="radio"
              className=""
              name="q6"
              value="b"
              onChange={(e) => {
                setAnswer6(e.target.value);
              }}
            />
            &nbsp;Disagree&nbsp;
          </p>

          <p>
            <input
              type="radio"
              className=""
              name="q6"
              value="c"
              onChange={(e) => {
                setAnswer6(e.target.value);
              }}
            />
            &nbsp;Neutral&nbsp;
          </p>

          <p>
            <input
              type="radio"
              className=""
              name="q6"
              value="d"
              onChange={(e) => {
                setAnswer6(e.target.value);
              }}
            />
            &nbsp;Agree&nbsp;
          </p>
          <p>
            <input
              type="radio"
              className=""
              name="q6"
              value="e"
              onChange={(e) => {
                setAnswer6(e.target.value);
              }}
            />
            &nbsp;Strongly agree&nbsp;
          </p>
        </div>
        <label className="text-lg">
          <mark className="bg-white font-semibold">Question 7</mark> Indicate
          the extent to which you agree with the statement "Computer systems are
          incapable of making mistakes"
        </label>
        <div className="flex flex-col relative">
          <p>
            <input
              type="radio"
              className=""
              name="q7"
              value="a"
              onChange={(e) => {
                setAnswer7(e.target.value);
              }}
              required
            />
            &nbsp;Strongly disagree&nbsp;
          </p>
          <p>
            <input
              type="radio"
              className=""
              name="q7"
              value="b"
              onChange={(e) => {
                setAnswer7(e.target.value);
              }}
            />
            &nbsp;Disagree&nbsp;
          </p>

          <p>
            <input
              type="radio"
              className=""
              name="q7"
              value="c"
              onChange={(e) => {
                setAnswer7(e.target.value);
              }}
            />
            &nbsp;Neutral&nbsp;
          </p>

          <p>
            <input
              type="radio"
              className=""
              name="q7"
              value="d"
              onChange={(e) => {
                setAnswer7(e.target.value);
              }}
            />
            &nbsp;Agree&nbsp;
          </p>
          <p>
            <input
              type="radio"
              className=""
              name="q7"
              value="e"
              onChange={(e) => {
                setAnswer7(e.target.value);
              }}
            />
            &nbsp;Strongly agree&nbsp;
          </p>
        </div>
        <label className="text-lg">
          <mark className="bg-white font-semibold">Question 8</mark> Indicate
          the extent to which you agree with the statement "Computer systems are
          trustworthy"
        </label>
        <div className="flex flex-col relative">
          <p>
            <input
              type="radio"
              className=""
              name="q8"
              value="a"
              onChange={(e) => {
                setAnswer8(e.target.value);
              }}
              required
            />
            &nbsp;Strongly disagree&nbsp;
          </p>
          <p>
            <input
              type="radio"
              className=""
              name="q8"
              value="b"
              onChange={(e) => {
                setAnswer8(e.target.value);
              }}
            />
            &nbsp;Disagree&nbsp;
          </p>

          <p>
            <input
              type="radio"
              className=""
              name="q8"
              value="c"
              onChange={(e) => {
                setAnswer8(e.target.value);
              }}
            />
            &nbsp;Neutral&nbsp;
          </p>

          <p>
            <input
              type="radio"
              className=""
              name="q8"
              value="d"
              onChange={(e) => {
                setAnswer8(e.target.value);
              }}
            />
            &nbsp;Agree&nbsp;
          </p>
          <p>
            <input
              type="radio"
              className=""
              name="q8"
              value="e"
              onChange={(e) => {
                setAnswer8(e.target.value);
              }}
            />
            &nbsp;Strongly agree&nbsp;
          </p>
        </div>
        <label className="text-lg">
          <mark className="bg-white font-semibold">Question 9</mark> When
          working with a decision support system, to what degree do/would you
          take ownership of the resulting decision?
        </label>
        <div className="flex flex-col relative">
          <p>
            <input
              type="radio"
              className=""
              name="q9"
              value="a"
              onChange={(e) => {
                setAnswer9(e.target.value);
              }}
              required
            />
            &nbsp;Strongly disagree&nbsp;
          </p>
          <p>
            <input
              type="radio"
              className=""
              name="q9"
              value="b"
              onChange={(e) => {
                setAnswer9(e.target.value);
              }}
            />
            &nbsp;Disagree&nbsp;
          </p>

          <p>
            <input
              type="radio"
              className=""
              name="q9"
              value="c"
              onChange={(e) => {
                setAnswer9(e.target.value);
              }}
            />
            &nbsp;Neutral&nbsp;
          </p>

          <p>
            <input
              type="radio"
              className=""
              name="q9"
              value="d"
              onChange={(e) => {
                setAnswer9(e.target.value);
              }}
            />
            &nbsp;Agree&nbsp;
          </p>
          <p>
            <input
              type="radio"
              className=""
              name="q9"
              value="e"
              onChange={(e) => {
                setAnswer9(e.target.value);
              }}
            />
            &nbsp;Strongly agree&nbsp;
          </p>
        </div>
        <label className="text-lg">
          <mark className="bg-white font-semibold">Question 10</mark> Indicate
          the extent to which you agree with the statement "I would feel guilty
          if I worked with a decision support system and made a wrong decision"
        </label>
        <div className="flex flex-col relative">
          <p>
            <input
              type="radio"
              className=""
              name="q10"
              value="a"
              onChange={(e) => {
                setAnswer10(e.target.value);
              }}
              required
            />
            &nbsp;Strongly disagree&nbsp;
          </p>
          <p>
            <input
              type="radio"
              className=""
              name="q10"
              value="b"
              onChange={(e) => {
                setAnswer10(e.target.value);
              }}
            />
            &nbsp;Disagree&nbsp;
          </p>

          <p>
            <input
              type="radio"
              className=""
              name="q10"
              value="c"
              onChange={(e) => {
                setAnswer10(e.target.value);
              }}
            />
            &nbsp;Neutral&nbsp;
          </p>

          <p>
            <input
              type="radio"
              className=""
              name="q10"
              value="d"
              onChange={(e) => {
                setAnswer10(e.target.value);
              }}
            />
            &nbsp;Agree&nbsp;
          </p>
          <p>
            <input
              type="radio"
              className=""
              name="q10"
              value="e"
              onChange={(e) => {
                setAnswer10(e.target.value);
              }}
            />
            &nbsp;Strongly agree&nbsp;
          </p>
        </div>
        <label className="text-lg">
          <mark className="bg-white font-semibold">Question 11</mark> Did you
          know about decision support systems before today?
        </label>
        <div className="flex flex-col relative">
          <p>
            <input
              type="radio"
              className=""
              name="q11"
              value="a"
              onChange={(e) => {
                setAnswer11(e.target.value);
              }}
              required
            />
            &nbsp;Yes&nbsp;
          </p>
          <p>
            <input
              type="radio"
              className=""
              name="q11"
              value="b"
              onChange={(e) => {
                setAnswer11(e.target.value);
              }}
            />
            &nbsp;No&nbsp;
          </p>
        </div>
        <label className="text-lg">
          <mark className="bg-white font-semibold">Question 12</mark> Have you
          previously worked with decision support systems?
        </label>
        <div className="flex flex-col relative">
          <p>
            <input
              type="radio"
              className=""
              name="q12"
              value="a"
              onChange={(e) => {
                setAnswer12(e.target.value);
              }}
              required
            />
            &nbsp;Yes&nbsp;
          </p>
          <p>
            <input
              type="radio"
              className=""
              name="q12"
              value="b"
              onChange={(e) => {
                setAnswer12(e.target.value);
              }}
            />
            &nbsp;No&nbsp;
          </p>
        </div>

        <Link
          to={"/assignments_instructions3/" + id}
          className="rounded-md bg-blue-400 px-2 py-1 text-base mx-auto text-gray-900"
        >
          Next
        </Link>
      </form>
    </div>
  );
}

export default Questionnaire3;
