import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Pre_Task_QuestionnaireATI() {
  const location = useLocation();
  const id = location.state.id;
  const task_ordering_group = location.state.task_ordering_group;
  const batch_order = location.state.batch_order;
  const [attention_checks_failed, setAttentionChecksFailed] = useState(
    location.state.attention_checks_failed
  );

  //FINISH THE ATTENTION CHECK
  const [attentionCheck, setAttentionCheck] = useState(""); //can adjust if needed
  const [answerATI1, setAnswerATI1] = useState("");
  const [answerATI2, setAnswerATI2] = useState("");
  const [answerATI3, setAnswerATI3] = useState("");
  const [answerATI4, setAnswerATI4] = useState("");
  const [answerATI5, setAnswerATI5] = useState("");
  const [answerATI6, setAnswerATI6] = useState("");
  const [answerATI7, setAnswerATI7] = useState("");
  const [answerATI8, setAnswerATI8] = useState("");
  const [answerATI9, setAnswerATI9] = useState("");

  function displayAnswers() {
    return (
      "Attention check: " +
      attentionCheck +
      "\nanswerATI1: " +
      answerATI1 +
      "\nanswerATI2: " +
      answerATI2 +
      "\nanswerATI3: " +
      answerATI3 +
      "\nanswerATI4: " +
      answerATI4 +
      "\nanswerATI5: " +
      answerATI5 +
      "\nanswerATI6: " +
      answerATI6 +
      "\nanswerATI7: " +
      answerATI7 +
      "\nanswerATI8: " +
      answerATI8 +
      "\nanswerATI9: " +
      answerATI9
    );
  }

  // useEffect(() => {
  //   console.log(displayAnswers());
  // }, [answerATI9]);

  useEffect(() => {
    if (allQuestionsHaveBeenAnswered()) {
      setShowQuestionsText(false);
    }
  }, [
    answerATI1,
    answerATI2,
    answerATI3,
    answerATI4,
    answerATI5,
    answerATI6,
    answerATI7,
    answerATI8,
    answerATI9,
  ]);

  const navigate = useNavigate();

  // const [notAllQuestionsHaveBeenAnswered, setNotAllQuestionsHaveBeenAnswered] =
  //   useState(true);

  const countDownValue = 0;
  const [countDown, setCountDown] = useState(countDownValue);
  const [showTimerText, setShowTimerText] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
      } else {
        setShowTimerText(false);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);

  function displayTimerText() {
    if (showTimerText) {
      return (
        <p className="text-red-500 font-bold">
          Don't hurry your answers. We expect you to spend at least 20 seconds
          on this questionnaire.
        </p>
      );
    } else {
      return;
    }
  }

  const [showQuestionsText, setShowQuestionsText] = useState(false);

  function attentionCheckHasBeenAnswered() {
    if (attentionCheck !== "") {
      return true;
    } else {
      return false;
    }
  }

  function attentionCheckIsCorrect() {
    if (attentionCheck === "right") {
      return true;
    } else {
      return false;
    }
  }

  function allQuestionsHaveBeenAnswered() {
    if (
      answerATI1 !== "" &&
      answerATI2 !== "" &&
      answerATI3 !== "" &&
      answerATI4 !== "" &&
      answerATI5 !== "" &&
      answerATI6 !== "" &&
      answerATI7 !== "" &&
      answerATI8 !== "" &&
      answerATI9 !== ""
    ) {
      return true;
    } else {
      return false;
    }
  }

  function sendAttentionCheckInfo(atp) {
    var json_response = {};
    json_response.id = id;
    json_response.stage = "t1_ATI";
    json_response.passed = atp;
    fetch(
      "https://thesis-crowdworker-app-backend-k5cvyplkba-ez.a.run.app/user-group/attention-checks/main",
      {
        mode: "no-cors",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(json_response),
      }
    );
  }

  function clickNext() {
    let atp = false;
    if (attentionCheck === "right") {
      atp = true;
    }

    sendAttentionCheckInfo(atp);

    var json_response = {};
    json_response.id = id;
    json_response.question1 = answerATI1;
    json_response.question2 = answerATI2;
    json_response.question3 = answerATI3;
    json_response.question4 = answerATI4;
    json_response.question5 = answerATI5;
    json_response.question6 = answerATI6;
    json_response.question7 = answerATI7;
    json_response.question8 = answerATI8;
    json_response.question9 = answerATI9;

    fetch(
      "https://thesis-crowdworker-app-backend-k5cvyplkba-ez.a.run.app/user-group/ati",
      {
        mode: "no-cors",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(json_response),
      }
    );

    // CODE TO SEND ALL QUESTION INFO TO THE DATABASE
    navigate("/assignments_instructions1/", {
      state: {
        attention_checks_failed: attention_checks_failed,
        id: id,
        task_ordering_group: task_ordering_group,
        batch_order: batch_order,
      },
    });
  }

  function clickNextNotAllQuestionsAnswered() {
    setShowQuestionsText(true);
  }

  function clickNextTimerNotZero() {
    setShowTimerText(true);
  }

  function displayQuestionsText() {
    if (showQuestionsText) {
      return (
        <p className="text-red-500 font-bold">
          Please answer all questions before pressing next.
        </p>
      );
    } else {
      return;
    }
  }

  function showNextButton() {
    if (attentionCheckHasBeenAnswered() && attentionCheckIsCorrect()) {
      if (countDown === 0) {
        if (allQuestionsHaveBeenAnswered()) {
          return (
            <button
              onClick={clickNext}
              className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
              value="next"
            >
              Next
            </button>
          );
        } else {
          return (
            <button
              onClick={clickNextNotAllQuestionsAnswered}
              className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
              value="next"
            >
              Next
            </button>
          );
        }
      } else {
        return (
          <button
            onClick={clickNextTimerNotZero}
            className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
            value="next"
          >
            Next
          </button>
        );
      }
    } else {
      return (
        <a
          onClick={clickWrong}
          href="https://app.prolific.co/submissions/complete?cc=C11CKFDX"
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
        >
          Next
        </a>
      );
    }
  }

  function clickWrong() {
    sendAttentionCheckInfo(false);
  }

  return (
    <div className="m-2">
      <form className="relative flex flex-col space-y-4">
        <p className="text-2xl text-center text-blue-400 font-bold">
          Please answer the following questions truthfully.
        </p>
        <p className="text-lg font-bold">
          In the following questions, we will ask you about your interaction
          with technical systems. The term “technical systems” refers to apps
          and other software applications, as well as entire digital devices
          (e.g., mobile phone, computer, TV, car navigation).
        </p>
        <hr />
        <br />
        <label className="text-lg">
          <mark className="bg-white font-bold">1.</mark> I like to occupy myself
          in greater detail with technical systems.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati1-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswerATI1(e.target.value);
                }}
                required
                name="ati1"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati1-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Completely disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati1-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswerATI1(e.target.value);
                }}
                name="ati1"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati1-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Largely disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati1-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswerATI1(e.target.value);
                }}
                name="ati1"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati1-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Slightly disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati1-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswerATI1(e.target.value);
                }}
                name="ati1"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati1-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Slightly agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati1-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswerATI1(e.target.value);
                }}
                name="ati1"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati1-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Largely agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati1-f"
                type="radio"
                value={5}
                onChange={(e) => {
                  setAnswerATI1(e.target.value);
                }}
                name="ati1"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati1-f"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Completely agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-bold">2.</mark> I like testing the
          functions of new technical systems.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati2-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswerATI2(e.target.value);
                }}
                required
                name="ati2"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati2-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Completely disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati2-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswerATI2(e.target.value);
                }}
                name="ati2"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati2-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Largely disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati2-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswerATI2(e.target.value);
                }}
                name="ati2"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati2-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Slightly disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati2-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswerATI2(e.target.value);
                }}
                name="ati2"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati2-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Slightly agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati2-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswerATI2(e.target.value);
                }}
                name="ati2"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati2-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Largely agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati2-f"
                type="radio"
                value={5}
                onChange={(e) => {
                  setAnswerATI2(e.target.value);
                }}
                name="ati2"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati2-f"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Completely agree
              </label>
            </div>
          </li>
        </ul>{" "}
        <br />
        <label className="text-lg">
          <mark className="bg-white font-bold">3.</mark> I predominantly deal
          with technical systems because I have to.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati3-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswerATI3(e.target.value);
                }}
                required
                name="ati3"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati3-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Completely disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati3-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswerATI3(e.target.value);
                }}
                name="ati3"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati3-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Largely disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati3-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswerATI3(e.target.value);
                }}
                name="ati3"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati3-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Slightly disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati3-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswerATI3(e.target.value);
                }}
                name="ati3"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati3-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Slightly agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati3-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswerATI3(e.target.value);
                }}
                name="ati3"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati3-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Largely agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati3-f"
                type="radio"
                value={5}
                onChange={(e) => {
                  setAnswerATI3(e.target.value);
                }}
                name="ati3"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati3-f"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Completely agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-bold">4.</mark> When I have a new
          technical system in front of me, I try it out intensively.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati4-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswerATI4(e.target.value);
                }}
                required
                name="ati4"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati4-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Completely disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati4-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswerATI4(e.target.value);
                }}
                name="ati4"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati4-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Largely disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati4-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswerATI4(e.target.value);
                }}
                name="ati4"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati4-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Slightly disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati4-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswerATI4(e.target.value);
                }}
                name="ati4"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati4-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Slightly agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati4-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswerATI4(e.target.value);
                }}
                name="ati4"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati4-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Largely agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati4-f"
                type="radio"
                value={5}
                onChange={(e) => {
                  setAnswerATI4(e.target.value);
                }}
                name="ati4"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati4-f"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Completely agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-bold">5.</mark> I enjoy spending time
          becoming acquainted with a new technical system.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati5-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswerATI5(e.target.value);
                }}
                required
                name="ati5"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati5-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Completely disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati5-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswerATI5(e.target.value);
                }}
                name="ati5"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati5-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Largely disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati5-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswerATI5(e.target.value);
                }}
                name="ati5"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati5-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Slightly disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati5-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswerATI5(e.target.value);
                }}
                name="ati5"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati5-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Slightly agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati5-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswerATI5(e.target.value);
                }}
                name="ati5"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati5-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Largely agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati5-f"
                type="radio"
                value={5}
                onChange={(e) => {
                  setAnswerATI5(e.target.value);
                }}
                name="ati5"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati5-f"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Completely agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-bold">6.</mark> Please select the
          button labeled "Slightly agree".
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-at-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAttentionCheck("wrong");
                }}
                required
                name="at"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-at-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Completely disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-at-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAttentionCheck("wrong");
                }}
                name="at"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-at-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Largely disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-at-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAttentionCheck("wrong");
                }}
                name="at"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-at-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Slightly disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-at-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAttentionCheck("right");
                }}
                name="at"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-at-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Slightly agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-at-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAttentionCheck("wrong");
                }}
                name="at"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-at-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Largely agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-at-f"
                type="radio"
                value={5}
                onChange={(e) => {
                  setAttentionCheck("wrong");
                }}
                name="at"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-at-f"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Completely agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-bold">7.</mark> It is enough for me
          that a technical system works; I don't care how or why.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati6-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswerATI6(e.target.value);
                }}
                required
                name="ati6"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati6-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Completely disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati6-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswerATI6(e.target.value);
                }}
                name="ati6"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati6-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Largely disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati6-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswerATI6(e.target.value);
                }}
                name="ati6"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati6-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Slightly disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati6-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswerATI6(e.target.value);
                }}
                name="ati6"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati6-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Slightly agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati6-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswerATI6(e.target.value);
                }}
                name="ati6"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati6-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Largely agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati6-f"
                type="radio"
                value={5}
                onChange={(e) => {
                  setAnswerATI6(e.target.value);
                }}
                name="ati6"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati6-f"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Completely agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-bold">8.</mark> I try to understand how
          a technical system exactly works.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati7-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswerATI7(e.target.value);
                }}
                required
                name="ati7"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati7-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Completely disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati7-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswerATI7(e.target.value);
                }}
                name="ati7"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati7-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Largely disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati7-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswerATI7(e.target.value);
                }}
                name="ati7"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati7-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Slightly disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati7-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswerATI7(e.target.value);
                }}
                name="ati7"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati7-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Slightly agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati7-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswerATI7(e.target.value);
                }}
                name="ati7"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati7-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Largely agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati7-f"
                type="radio"
                value={5}
                onChange={(e) => {
                  setAnswerATI7(e.target.value);
                }}
                name="ati7"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati7-f"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Completely agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-bold">9.</mark> It is enough for me to
          know the basic functions of a technical system.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati8-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswerATI8(e.target.value);
                }}
                required
                name="ati8"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati8-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Completely disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati8-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswerATI8(e.target.value);
                }}
                name="ati8"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati8-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Largely disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati8-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswerATI8(e.target.value);
                }}
                name="ati8"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati8-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Slightly disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati8-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswerATI8(e.target.value);
                }}
                name="ati8"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati8-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Slightly agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati8-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswerATI8(e.target.value);
                }}
                name="ati8"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati8-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Largely agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati8-f"
                type="radio"
                value={5}
                onChange={(e) => {
                  setAnswerATI8(e.target.value);
                }}
                name="ati8"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati8-f"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Completely agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-bold">10.</mark> I try to make full use
          of the capabilities of a technical system.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati9-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswerATI9(e.target.value);
                }}
                required
                name="ati9"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati9-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Completely disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati9-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswerATI9(e.target.value);
                }}
                name="ati9"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati9-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Largely disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati9-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswerATI9(e.target.value);
                }}
                name="ati9"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati9-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Slightly disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati9-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswerATI9(e.target.value);
                }}
                name="ati9"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati9-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Slightly agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati9-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswerATI9(e.target.value);
                }}
                name="ati9"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati9-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Largely agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-ati9-f"
                type="radio"
                value={5}
                onChange={(e) => {
                  setAnswerATI9(e.target.value);
                }}
                name="ati9"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-ati9-f"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Completely agree
              </label>
            </div>
          </li>
        </ul>
      </form>
      <br />
      <br />
      <div className="flex">{showNextButton()}</div>
      <div className="flex">{displayQuestionsText()}</div>
      <br />
      <div className="flex">{displayTimerText()}</div>
    </div>
  );
}

export default Pre_Task_QuestionnaireATI;
