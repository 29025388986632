import "./HomePage2.css";
import { Link, useSearchParams } from "react-router-dom";

function HomePage2() {
  const [searchParams, setSearchParams] = useSearchParams();

  const id = searchParams.get("PROLIFIC_PID");

  return (
    <div className="flex flex-col mx-auto max-w-6xl space-y-6 py-4 m-2">
      <h1 className="text-xl text-center">
        <span className="font-bold text-blue-400 text-4xl">Welcome</span>
      </h1>
      <p className="text-justify text-lg">
        Welcome to this study. In this study, you will be asked to check 20
        hotel reviews and identify which ones are deceptive.
      </p>
      <p className="text-justify text-lg">
        At each task, you will be presented with a hotel review. Using the
        instructions and guidelines, you will have to identify which reviews are
        genuine, and which are deceptive. Detailed instructions and guidelines
        will be provided before the tasks.
      </p>
      <p className="text-justify text-lg">
        On top of the standard payment, you will be paid 0.05 GBP per task that
        you correctly answer. This means that each correct decision you make
        will be rewarded with a bonus.
      </p>
      <p className="text-justify text-lg">
        By clicking continue on the first page you confirm that you are
        participating in this experiment and know that your answers are recorded
        for research.
      </p>
      <br />
      <Link
        to={"/assignments_instructions3/" + id}
        className="rounded-sm bg-blue-400 px-4 py-4 text-base mx-auto text-gray-900 font-semibold"
      >
        CONTINUE
      </Link>
    </div>
  );
}

export default HomePage2;
