import { Link, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

function Assignments_Instructions_NT() {
  const location = useLocation();
  const id = location.state.id;
  const task_ordering_group = location.state.task_ordering_group;
  const batch_order = location.state.batch_order;
  const [attention_checks_failed, setAttentionChecksFailed] = useState(
    location.state.attention_checks_failed
  );

  const countDownValue = 0;
  const [countDown, setCountDown] = useState(countDownValue);
  const [showTimerText, setShowTimerText] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
      } else {
        setShowTimerText(false);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);

  function displayTimerText() {
    if (showTimerText) {
      return (
        <p className="text-red-500 font-bold">
          Please read the instructions carefully before you continue.
        </p>
      );
    } else {
      return;
    }
  }

  function showNextButton() {
    if (countDown === 0) {
      return (
        <Link
          to={"/assignments_nt/"}
          state={{
            attention_checks_failed: attention_checks_failed,
            id: id,
            task_ordering_group: task_ordering_group,
            batch_order: batch_order,
          }}
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900"
        >
          Next
        </Link>
      );
    } else {
      return (
        <button
          onClick={() => {
            setShowTimerText(true);
          }}
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900"
          value="next"
        >
          Next
        </button>
      );
    }
  }

  return (
    <div className="flex flex-col mx-auto max-w-6xl space-y-6 py-4 m-2">
      <h1 className="text-xl text-center">
        <span className="font-bold text-blue-400 text-4xl">
          Task Instructions
        </span>
      </h1>
      <p className="text-justify text-lg">
        Great job! In this stage, you will be presented with a similar set of
        questions. This time, there will be 8 of them. Again, feel free to check
        up on the guidelines by clicking the button that we provide you with.
      </p>
      {showNextButton()}
      {displayTimerText()}
    </div>
  );
}

export default Assignments_Instructions_NT;
