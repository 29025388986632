import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Post_Task_Questionnaire() {
  const location = useLocation();
  const id = location.state.id;
  const task_ordering_group = location.state.task_ordering_group;
  const batch_order = location.state.batch_order;

  const [attention_checks_failed, setAttentionChecksFailed] = useState(
    location.state.attention_checks_failed
  );

  //FINISH THE ATTENTION CHECK
  const [attentionCheck, setAttentionCheck] = useState(""); //can adjust if needed
  const [answer1, setAnswer1] = useState(""); //can adjust if needed
  const [answer2, setAnswer2] = useState("");
  const [answer4, setAnswer4] = useState("");
  const [answer6, setAnswer6] = useState("");
  const [answer7, setAnswer7] = useState("");
  const [answer8, setAnswer8] = useState("");
  const [answer9, setAnswer9] = useState("");
  const [answer10, setAnswer10] = useState("");
  const [answer11, setAnswer11] = useState("");
  const [answer13, setAnswer13] = useState("");
  const [answer14, setAnswer14] = useState("");
  const [answer15, setAnswer15] = useState("");
  const [answer16, setAnswer16] = useState("");
  const [answer19, setAnswer19] = useState("");

  function displayAnswers() {
    return (
      "Attention check: " +
      attentionCheck +
      "\nAnswer1: " +
      answer1 +
      "\nAnswer2: " +
      answer2 +
      "\nAnswer4: " +
      answer4 +
      "\nAnswer6: " +
      answer6 +
      "\nAnswer7: " +
      answer7 +
      "\nAnswer8: " +
      answer8 +
      "\nAnswer9: " +
      answer9 +
      "\nAnswer10: " +
      answer10 +
      "\nAnswer11: " +
      answer11 +
      "\nAnswer13: " +
      answer13 +
      "\nAnswer14: " +
      answer14 +
      "\nAnswer15: " +
      answer15 +
      "\nAnswer16: " +
      answer16 +
      "\nAnswer19: " +
      answer19
    );
  }
  // useEffect(() => {
  //   console.log(displayAnswers());
  // }, [answer19]);

  useEffect(() => {
    if (allQuestionsHaveBeenAnswered()) {
      setShowQuestionsText(false);
    }
  }, [
    answer1,
    answer2,
    answer4,
    answer6,
    answer7,
    answer8,
    answer9,
    answer10,
    answer11,
    answer13,
    answer14,
    answer15,
    answer16,
    answer19,
  ]);

  const navigate = useNavigate();

  // const [notAllQuestionsHaveBeenAnswered, setNotAllQuestionsHaveBeenAnswered] =
  //   useState(true);

  const countDownValue = 0;
  const [countDown, setCountDown] = useState(countDownValue);
  const [showTimerText, setShowTimerText] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
      } else {
        setShowTimerText(false);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);

  function displayTimerText() {
    if (showTimerText) {
      return (
        <p className="text-red-500 font-bold">
          Don't hurry your answers. We expect you to spend at least 30 seconds
          on this questionnaire.
        </p>
      );
    } else {
      return;
    }
  }

  const [showQuestionsText, setShowQuestionsText] = useState(false);

  function attentionCheckHasBeenAnswered() {
    if (attentionCheck !== "") {
      return true;
    } else {
      return false;
    }
  }

  function attentionCheckIsCorrect() {
    if (attentionCheck === "right") {
      return true;
    } else {
      return false;
    }
  }

  function allQuestionsHaveBeenAnswered() {
    if (
      answer1 !== "" &&
      answer2 !== "" &&
      answer4 !== "" &&
      answer6 !== "" &&
      answer7 !== "" &&
      answer8 !== "" &&
      answer9 !== "" &&
      answer10 !== "" &&
      answer11 !== "" &&
      answer13 !== "" &&
      answer14 !== "" &&
      answer15 !== "" &&
      answer16 !== "" &&
      answer19 !== ""
    ) {
      return true;
    } else {
      return false;
    }
  }

  function sendAttentionCheckInfo(atp) {
    var json_response = {};
    json_response.id = id;
    json_response.stage = "post_task_questionnaire_1";
    json_response.passed = atp;
    fetch(
      "https://thesis-crowdworker-app-backend-k5cvyplkba-ez.a.run.app/user-group/attention-checks/main",
      {
        mode: "no-cors",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(json_response),
      }
    );
  }

  function clickNext() {
    sendAttentionCheckInfo(true);
    // CODE TO SEND ALL QUESTION INFO TO THE DATABASE
    let json_response = {};
    json_response.id = id;
    json_response.question1 = answer1;
    json_response.question2 = answer2;
    json_response.question4 = answer4;
    json_response.question6 = answer6;
    json_response.question7 = answer7;
    json_response.question8 = answer8;
    json_response.question9 = answer9;
    json_response.question10 = answer10;
    json_response.question11 = answer11;
    json_response.question13 = answer13;
    json_response.question14 = answer14;
    json_response.question15 = answer15;
    json_response.question16 = answer16;
    json_response.question19 = answer19;

    fetch(
      "https://thesis-crowdworker-app-backend-k5cvyplkba-ez.a.run.app/user-group/post-task-questionnaire-tia-1",
      {
        mode: "no-cors",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(json_response),
      }
    );

    if (task_ordering_group === 3) {
      navigate("/assignments_instructions_nt/", {
        state: {
          attention_checks_failed: attention_checks_failed,
          id: id,
          task_ordering_group: task_ordering_group,
          batch_order: batch_order,
        },
      });
    } else {
      navigate("/debugging_instructions/", {
        state: {
          attention_checks_failed: attention_checks_failed,
          id: id,
          task_ordering_group: task_ordering_group,
          batch_order: batch_order,
        },
      });
    }
  }

  function clickNext2() {
    sendAttentionCheckInfo(false);
    // CODE TO SEND ALL QUESTION INFO TO THE DATABASE
    let json_response = {};
    json_response.id = id;
    json_response.question1 = answer1;
    json_response.question2 = answer2;
    json_response.question4 = answer4;
    json_response.question6 = answer6;
    json_response.question7 = answer7;
    json_response.question8 = answer8;
    json_response.question9 = answer9;
    json_response.question10 = answer10;
    json_response.question11 = answer11;
    json_response.question13 = answer13;
    json_response.question14 = answer14;
    json_response.question15 = answer15;
    json_response.question16 = answer16;
    json_response.question19 = answer19;

    fetch(
      "https://thesis-crowdworker-app-backend-k5cvyplkba-ez.a.run.app/user-group/post-task-questionnaire-tia-1",
      {
        mode: "no-cors",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(json_response),
      }
    );

    if (task_ordering_group === 3) {
      navigate("/assignments_instructions_nt/", {
        state: {
          attention_checks_failed: attention_checks_failed + 1,
          id: id,
          task_ordering_group: task_ordering_group,
          batch_order: batch_order,
        },
      });
    } else {
      navigate("/debugging_instructions/", {
        state: {
          attention_checks_failed: attention_checks_failed + 1,
          id: id,
          task_ordering_group: task_ordering_group,
          batch_order: batch_order,
        },
      });
    }
  }

  function clickWrong() {
    sendAttentionCheckInfo(false);
  }

  function clickNextNotAllQuestionsAnswered() {
    setShowQuestionsText(true);
  }

  function clickNextTimerNotZero() {
    setShowTimerText(true);
  }

  function displayQuestionsText() {
    if (showQuestionsText) {
      return (
        <p className="text-red-500 font-bold">
          Please answer all questions before pressing next.
        </p>
      );
    } else {
      return;
    }
  }

  function showNextButton() {
    if (attentionCheckHasBeenAnswered() && attentionCheckIsCorrect()) {
      if (countDown === 0) {
        if (allQuestionsHaveBeenAnswered()) {
          return (
            <button
              onClick={clickNext}
              className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
              value="next"
            >
              Next
            </button>
          );
        } else {
          return (
            <button
              onClick={clickNextNotAllQuestionsAnswered}
              className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
              value="next"
            >
              Next
            </button>
          );
        }
      } else {
        return (
          <button
            onClick={clickNextTimerNotZero}
            className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
            value="next"
          >
            Next
          </button>
        );
      }
    } else {
      if (attention_checks_failed >= 1) {
        return (
          <a
            onClick={clickWrong}
            href="https://app.prolific.co/submissions/complete?cc=C11CKFDX"
            className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
          >
            Next
          </a>
        );
      } else {
        if (countDown === 0) {
          if (allQuestionsHaveBeenAnswered()) {
            return (
              <button
                onClick={clickNext2}
                className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
                value="next"
              >
                Next
              </button>
            );
          } else {
            return (
              <button
                onClick={clickNextNotAllQuestionsAnswered}
                className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
                value="next"
              >
                Next
              </button>
            );
          }
        } else {
          return (
            <button
              onClick={clickNextTimerNotZero}
              className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold hover:opacity-80"
              value="next"
            >
              Next
            </button>
          );
        }
      }
    }
  }

  return (
    <div className="m-2">
      <form className="relative flex flex-col space-y-4">
        <p className="text-2xl text-center text-blue-400 font-bold">
          Please answer the following questions truthfully.
        </p>
        <p className="text-lg font-bold">
          When answering the questions, consider the AI system that has
          supported you during the previous assignment.
        </p>
        <br></br>
        <label className="text-lg">
          <mark className="bg-white font-semibold">1.</mark> The system is
          capable of interpreting situations correctly.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q1-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswer1(e.target.value);
                }}
                name="q1"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                required
              ></input>
              <label
                for="horizontal-list-radio-q1-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q1-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswer1(e.target.value);
                }}
                name="q1"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q1-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q1-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswer1(e.target.value);
                }}
                name="q1"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q1-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Neither disagree nor agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q1-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswer1(e.target.value);
                }}
                name="q1"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q1-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q1-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswer1(e.target.value);
                }}
                name="q1"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q1-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-semibold">2.</mark> The system state
          was always clear to me.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q2-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswer2(e.target.value);
                }}
                name="q2"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                required
              ></input>
              <label
                for="horizontal-list-radio-q2-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q2-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswer2(e.target.value);
                }}
                name="q2"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q2-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q2-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswer2(e.target.value);
                }}
                name="q2"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q2-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Neither disagree nor agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q2-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswer2(e.target.value);
                }}
                name="q2"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q2-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q2-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswer2(e.target.value);
                }}
                name="q2"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q2-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-semibold">3.</mark> The developers are
          trustworthy.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q4-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswer4(e.target.value);
                }}
                name="q4"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                required
              ></input>
              <label
                for="horizontal-list-radio-q4-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q4-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswer4(e.target.value);
                }}
                name="q4"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q4-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q4-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswer4(e.target.value);
                }}
                name="q4"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q4-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Neither disagree nor agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q4-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswer4(e.target.value);
                }}
                name="q4"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q4-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q4-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswer4(e.target.value);
                }}
                name="q4"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q4-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-semibold">4.</mark> The system works
          reliably.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q6-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswer6(e.target.value);
                }}
                name="q6"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                required
              ></input>
              <label
                for="horizontal-list-radio-q6-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q6-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswer6(e.target.value);
                }}
                name="q6"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q6-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q6-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswer6(e.target.value);
                }}
                name="q6"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q6-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Neither disagree nor agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q6-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswer6(e.target.value);
                }}
                name="q6"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q6-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q6-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswer6(e.target.value);
                }}
                name="q6"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q6-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-semibold">5.</mark> The system reacts
          unpredictably.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q7-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswer7(e.target.value);
                }}
                name="q7"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                required
              ></input>
              <label
                for="horizontal-list-radio-q7-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q7-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswer7(e.target.value);
                }}
                name="q7"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q7-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q7-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswer7(e.target.value);
                }}
                name="q7"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q7-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Neither disagree nor agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q7-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswer7(e.target.value);
                }}
                name="q7"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q7-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q7-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswer7(e.target.value);
                }}
                name="q7"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q7-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-semibold">6.</mark> Please select the
          button labeled "Rather agree".
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-at-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAttentionCheck("wrong");
                }}
                name="at"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                required
              ></input>
              <label
                for="horizontal-list-radio-at-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-at-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAttentionCheck("wrong");
                }}
                name="at"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-at-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-at-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAttentionCheck("wrong");
                }}
                name="at"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-at-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Neither disagree nor agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-at-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAttentionCheck("right");
                }}
                name="at"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-at-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-at-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAttentionCheck("wrong");
                }}
                name="at"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-at-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-semibold">7.</mark> The developers take
          my well-being seriously.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q8-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswer8(e.target.value);
                }}
                name="q8"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                required
              ></input>
              <label
                for="horizontal-list-radio-q8-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q8-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswer8(e.target.value);
                }}
                name="q8"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q8-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q8-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswer8(e.target.value);
                }}
                name="q8"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q8-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Neither disagree nor agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q8-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswer8(e.target.value);
                }}
                name="q8"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q8-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q8-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswer8(e.target.value);
                }}
                name="q8"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q8-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-semibold">8.</mark> I trust the system.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q9-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswer9(e.target.value);
                }}
                name="q9"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                required
              ></input>
              <label
                for="horizontal-list-radio-q9-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q9-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswer9(e.target.value);
                }}
                name="q9"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q9-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q9-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswer9(e.target.value);
                }}
                name="q9"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q9-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Neither disagree nor agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q9-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswer9(e.target.value);
                }}
                name="q9"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q9-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q9-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswer9(e.target.value);
                }}
                name="q9"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q9-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-semibold">9.</mark> A system
          malfunction is likely.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q10-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswer10(e.target.value);
                }}
                name="q10"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                required
              ></input>
              <label
                for="horizontal-list-radio-q10-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q10-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswer10(e.target.value);
                }}
                name="q10"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q10-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q10-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswer10(e.target.value);
                }}
                name="q10"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q10-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Neither disagree nor agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q10-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswer10(e.target.value);
                }}
                name="q10"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q10-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q10-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswer10(e.target.value);
                }}
                name="q10"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q10-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-semibold">10.</mark> I was able to
          understand why things happened.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q11-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswer11(e.target.value);
                }}
                name="q11"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                required
              ></input>
              <label
                for="horizontal-list-radio-q11-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q11-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswer11(e.target.value);
                }}
                name="q11"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q11-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q11-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswer11(e.target.value);
                }}
                name="q11"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q11-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Neither disagree nor agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q11-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswer11(e.target.value);
                }}
                name="q11"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q11-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q11-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswer11(e.target.value);
                }}
                name="q11"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q11-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-semibold">11.</mark> The system is
          capable of taking over complicated tasks.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q13-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswer13(e.target.value);
                }}
                name="q13"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                required
              ></input>
              <label
                for="horizontal-list-radio-q13-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q13-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswer13(e.target.value);
                }}
                name="q13"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q13-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q13-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswer13(e.target.value);
                }}
                name="q13"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q13-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Neither disagree nor agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q13-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswer13(e.target.value);
                }}
                name="q13"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q13-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q13-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswer13(e.target.value);
                }}
                name="q13"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q13-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-semibold">12.</mark> I can rely on the
          system.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q14-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswer14(e.target.value);
                }}
                name="q14"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                required
              ></input>
              <label
                for="horizontal-list-radio-q14-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q14-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswer14(e.target.value);
                }}
                name="q14"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q14-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q14-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswer14(e.target.value);
                }}
                name="q14"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q14-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Neither disagree nor agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q14-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswer14(e.target.value);
                }}
                name="q14"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q14-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q14-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswer14(e.target.value);
                }}
                name="q14"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q14-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-semibold">13.</mark> The system might
          make sporadic errors.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q15-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswer15(e.target.value);
                }}
                name="q15"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                required
              ></input>
              <label
                for="horizontal-list-radio-q15-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q15-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswer15(e.target.value);
                }}
                name="q15"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q15-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q15-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswer15(e.target.value);
                }}
                name="q15"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q15-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Neither disagree nor agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q15-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswer15(e.target.value);
                }}
                name="q15"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q15-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q15-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswer15(e.target.value);
                }}
                name="q15"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q15-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-semibold">14.</mark> It is difficult to
          identify what the system will do next.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q16-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswer16(e.target.value);
                }}
                name="q16"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                required
              ></input>
              <label
                for="horizontal-list-radio-q16-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q16-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswer16(e.target.value);
                }}
                name="q16"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q16-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q16-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswer16(e.target.value);
                }}
                name="q16"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q16-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Neither disagree nor agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q16-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswer16(e.target.value);
                }}
                name="q16"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q16-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q16-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswer16(e.target.value);
                }}
                name="q16"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q16-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly agree
              </label>
            </div>
          </li>
        </ul>
        <br />
        <label className="text-lg">
          <mark className="bg-white font-semibold">15.</mark> I am confident
          about the system's capabilities.
        </label>
        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q19-a"
                type="radio"
                value={0}
                onChange={(e) => {
                  setAnswer19(e.target.value);
                }}
                name="q19"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                required
              ></input>
              <label
                for="horizontal-list-radio-q19-a"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly disagree{" "}
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q19-b"
                type="radio"
                value={1}
                onChange={(e) => {
                  setAnswer19(e.target.value);
                }}
                name="q19"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q19-b"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather disagree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q19-c"
                type="radio"
                value={2}
                onChange={(e) => {
                  setAnswer19(e.target.value);
                }}
                name="q19"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q19-c"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Neither disagree nor agree
              </label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q19-d"
                type="radio"
                value={3}
                onChange={(e) => {
                  setAnswer19(e.target.value);
                }}
                name="q19"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q19-d"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Rather agree
              </label>
            </div>
          </li>
          <li class="w-full dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="horizontal-list-radio-q19-e"
                type="radio"
                value={4}
                onChange={(e) => {
                  setAnswer19(e.target.value);
                }}
                name="q19"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              ></input>
              <label
                for="horizontal-list-radio-q19-e"
                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Strongly agree
              </label>
            </div>
          </li>
        </ul>
      </form>
      <br />
      <br />
      <div className="flex">{showNextButton()}</div>
      <div className="flex">{displayQuestionsText()}</div>
      <br />
      <div className="flex">{displayTimerText()}</div>
    </div>
  );
}

export default Post_Task_Questionnaire;
