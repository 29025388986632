import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import "./Attention_Check_1_Control.css";

function Attention_Check_1_Control() {
  const { id } = useParams();

  const [attentionCheck, setAttentionCheck] = useState(""); //can adjust if needed
  const [attentionCheck2, setAttentionCheck2] = useState(""); //can adjust if needed

  // useEffect(() => {
  //   console.log("Attention Check 1: " + attentionCheck);
  //   console.log("Attention Check 2: " + attentionCheck2);
  //   console.log(attentionCheck === "right" && attentionCheck2 === "right");
  // }, [attentionCheck, attentionCheck2]);

  function checkAttentionChecks() {
    if (attentionCheck === "right" && attentionCheck2 === "right") {
      return (
        <Link
          to={"/assignments_c/" + id}
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold"
        >
          Next
        </Link>
      );
    } else {
      return (
        <a
          href="https://app.prolific.co/submissions/complete?cc=C66DHLZ0"
          className="rounded-sm bg-blue-400 px-10 py-4 text-base mx-auto text-gray-900 font-semibold"
        >
          Next
        </a>
      );
    }
  }

  return (
    <div>
      <label className="text-lg">
        <mark className="bg-white font-semibold">1.</mark> In this study,
        deceptive reviews are generated by an AI system.
      </label>
      <div className="flex flex-col relative">
        <p>
          <input
            type="radio"
            className=""
            name="at"
            value="a"
            onChange={(e) => {
              setAttentionCheck("wrong");
            }}
            required
          />
          &nbsp;True
        </p>
        <p>
          <input
            type="radio"
            className=""
            name="at"
            value="b"
            onChange={(e) => {
              setAttentionCheck("right");
            }}
          />
          &nbsp;False
        </p>
      </div>
      <br></br>
      <label className="text-lg">
        <mark className="bg-white font-semibold">2.</mark> There are no
        guidelines for finding deceptive reviews
      </label>
      <div className="flex flex-col relative">
        <p>
          <input
            type="radio"
            className=""
            name="q1"
            value="a"
            onChange={(e) => {
              setAttentionCheck2("wrong");
            }}
            required
          />
          &nbsp;True
        </p>
        <p>
          <input
            type="radio"
            className=""
            name="q1"
            value="b"
            onChange={(e) => {
              setAttentionCheck2("right");
            }}
          />
          &nbsp;False
        </p>
      </div>
      <br />
      <div className="flex">{checkAttentionChecks()}</div>
    </div>
  );
}

export default Attention_Check_1_Control;
